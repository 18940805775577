import { useEffect } from 'react';
import { useMeeting } from './MeetingProvider';

const MeetingSubscriptionManager = () => {

    const { subscribe } = useMeeting();

    useEffect(() => {
        subscribe();
    }, [subscribe]);
};

export default MeetingSubscriptionManager;