import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../shared/Button';
import SettingsMusic from './Music';
import SettingsAudio from './Audio';
import SettingsReminders from './Reminders';
import ApiHelper from '../helpers/ApiHelper';
import UserHelper from '../helpers/UserHelper';
import SystemHelper from '../helpers/SystemHelper';
import { MeetingContext } from '../meeting/MeetingProvider';
import './Settings.scss';

class Settings extends Component {
    static contextType = MeetingContext;

    constructor(props) {
        super(props);
        this.history = this.props.history;
        this.state = {
            app_version: '',
            parentComponent: this.props.Parent,
            user: null,
            user_name: '',
            audio_volume: 5,
            programs: [],
            navMode: (this.props.card ? 'open' : ''),
            openCard: this.props.card,
            latest_version: null,
            show_update: false
        };

        // Refs
        this.reminders = React.createRef();
        this.audio = React.createRef();
        this.music = React.createRef();
    }

    async componentDidMount() {
        const component = this;

        this.is_installation = await SystemHelper.storage.get('admin_token') === 'installation';

        // Get app version
        this.getAppVersion();
        
        this.setVolume();

        // If individual user
        if (!this.is_installation) {
            // Get user
            UserHelper.getUser().then(user => {
                this.setState({ user: user });
                this.setState({ user_name: user.first_name });
            });

            // Get user's program
            ApiHelper.Get('/programs').then(programs => {
                component.setState({ programs: programs.data });
            });
        }
    }

    /**
     * Go to updater
     */
     updateApp() {
        //this.history.push('/update-app');
        this.history.push(this.is_installation ? '/dashboard-installation' : '/dashboard');
    }

    /**
     * Get current app version
     */
    getAppVersion() {
        // Get installed version number
        if (window.cordova) {
            window.cordova.getAppVersion.getVersionNumber().then(async (installed_version) => {
                this.setState({app_version: installed_version });
            });
        } else {
            this.setState({app_version: process.env.REACT_APP_VERSION });
        }
    }

    /**
     * Get output volume from local storage and set in state
     */
    async setVolume() {
        this.setState({ audio_volume: parseInt(await SystemHelper.storage.get('audio_volume')) });
    }

    /**
     * Change output volume
     * @param {1 or -1} change 
     */
    volume(change) {
        const vol = this.state.audio_volume;
        if (change === -1 && vol > 0) {
            this.setState({ audio_volume: vol - 1 });
        } else if (change === 1 && vol < 10) {
            this.setState({ audio_volume: vol + 1 });
        }
    }

    /**
     * Handle logout
     */
     logout() {
        const { unsubscribe } = this.context;
        // TODO: Confirm logout
        UserHelper.logout(this.history, unsubscribe);
    }
  
    /**
     * Save settings
     */
    async save() {
        if (!this.is_installation) {
            this.reminders.current.state.programs.forEach(async (program) =>  {
                // Save reminders in API
                await ApiHelper.Put('/programs/' + program.uuid + '/reminders', {
                    reminder_1: (program.meta?.reminder_1 ?? null),
                    reminder_2: (program.meta?.reminder_2 ?? null),
                    reminder_3: (program.meta?.reminder_3 ?? null),
                    reminder_4: (program.meta?.reminder_4 ?? null)
                });
            });
        }

        // Save audio volume locally
        if (this.audio.current) {
            await SystemHelper.storage.set('audio_volume', JSON.stringify(this.audio.current.state.audio_volume));
        }
    }

    back() {
        this.setState({ navMode: '' });
        this.reminders.current.setState({ reminderNavMode: "" });
        this.music.current.music.stop(true);
    }

    finish() {
        // If opened by parent component
        if (this.state.parentComponent) {
            // Close this component
            this.state.parentComponent.setState({ settings: false });

            if (!this.is_installation) {
                // Else just re-init program
                this.state.parentComponent.initProgram();
            }
        }
        // Else go to dashboard
        else {
            this.history.push('/dashboard');
        }     
    }

    render() {

        const { t } = this.state.parentComponent.props;

        return (
            <section id="view-settings" className="white slide-up">
                <div className="cover"></div>
                <div className="content">
                    <h2>{ t('dashboard:buttons.settings') }</h2>
                    { this.is_installation }
                    <div className={'scroll-cards ' + this.state.navMode}>
                        <div className="col-100">
                            <Button className={this.is_installation ? 'd-none' : 'center white full-width'} Icon="arrow-right-blue" IconLeft="notification-blue" onClick={() => this.setState({ navMode: 'open', openCard: 'reminders' })}>
                                <span className="d-block w-100 padding-1 text-left">{ t('settings:reminders') }</span>
                            </Button>
                            <Button className="center white full-width" Icon="arrow-right-blue" IconLeft="music-blue" onClick={() => this.setState({ navMode: 'open', openCard: 'music' })}>
                                <span className="d-block w-100 padding-1 text-left">{ t('settings:music') }</span>
                            </Button>
                            <Button className="center white full-width" Icon="arrow-right-blue" IconLeft="audio-blue" onClick={() => this.setState({ navMode: 'open', openCard: 'audio' })}>
                                <span className="d-block w-100 padding-1 text-left">{ t('settings:audio_levels') }</span>
                            </Button>
                            <Button className={(this.state.show_update ? '' : 'd-none') + " center white full-width"}  Icon="arrow-right-blue" onClick={() => this.updateApp()}>
                                <span className="d-block w-100 padding-1 text-left">{ t('settings:update_habbie') } ({ this.state.latest_version })</span>
                            </Button>
                            <Button className="center white full-width" IconLeft="logout-red" onClick={() => this.logout() }>
                                <span className="d-block w-100 padding-1 text-left color-red">{ t('settings:logout') }</span>
                            </Button>
                        </div>
                        <div className="col-100">
                            <div className="card shadow">
                                <div className={ this.is_installation || this.state.openCard !== 'reminders' ? 'd-none' : ''}>
                                    <SettingsReminders isInstallation={this.is_installation} ref={this.reminders} parent={this} />
                                </div>
                                <div className={ this.state.openCard !== 'music' ? 'd-none' : ''}>
                                    <SettingsMusic ref={this.music} parent={this} />
                                </div>
                                <div className={ this.state.openCard !== 'audio' ? 'd-none' : ''}>
                                    <SettingsAudio ref={this.audio} parent={this} />
                                </div>
                                <Button className="center yellow-light auto-width" IconLeft="arrow-left" onClick={() => this.back() }>{ t('common:back') }</Button>
                            </div>
                        </div>
                    </div>
                    <div className="button-bar bottom">
                        <Button className="center blue-dark auto-width" Icon="arrow-down" onClick={() => this.finish()}>{ t('settings:done') }</Button>
                    </div>
                    <div className="version">v. { this.state.app_version }</div>
                </div>
            </section>  
        );
    }
}

export default withRouter(Settings);