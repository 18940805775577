import * as tf from '@tensorflow/tfjs';
import * as poseDetection from '@tensorflow-models/pose-detection';
//import * as tf from '@tensorflow/tfjs-core';
import '@tensorflow/tfjs-backend-webgl';
//import * as bodyPix from '@tensorflow-models/body-pix';
import SystemHelper from '../../helpers/SystemHelper';

class PoseDetector {

    constructor(props) {
        this.detector = null;
        this.model = poseDetection.SupportedModels.PoseNet;
        this.ESTIMATE_INTERVAL = 2000;
        this.detectorConfig = {
          runtime: 'tfjs',
          enableSmoothing: false,
          modelType: 'full'
          //modelType: poseDetection.movenet.modelType.SINGLEPOSE_THUNDER
        };
        this.estimationConfig = { flipHorizontal: true };
        this.active = false;
        this.snapshot_image = null;
    }

    async init(component, video) {
        console.log('Running init()...');
        this.active = true;

        // Init Tensorflow
        await tf.ready();


        this.snapshot_image = document.createElement('img');
        this.snapshot_image.width = 1000;
        this.snapshot_image.height = 1000;

        //await SystemHelper.timeout(3000);


        // Create detector
        this.detector = await poseDetection.createDetector(this.model, this.detectorConfig);

        return new Promise(async (resolve, reject) => {
            // Run first estimate and resolve when done
            let image = await this.getSnapshot(video);
            await this.estimate(image).then(() => {
                // Start estimation loop
                setTimeout(() => { this.estimateLoop(component, video) }, this.ESTIMATE_INTERVAL);
                console.log('resolve init...');
                resolve();
            });
        });

        //this.initBlur(component, mirror);
    }

    async estimateLoop(component, video) {

        // Get snapshot from video
        //console.log('Getting snapshot...', video);
        let mirror = await this.getSnapshot(video);

        //console.log('mirror.src', mirror.src);

        // Skip if exercise if manually paused
        if (this.active && this.detector && !component.state.manually_paused) {

            // Get poses
            //console.log('Getting estimate...');
            const poses = await this.estimate(mirror);

            //console.log('POSES', poses);
            //console.log('POSES length', poses.length);

            if (poses.length && (poses[0].keypoints3D || poses[0].keypoints)) {
                const kp = poses[0].keypoints3D ?? poses[0].keypoints;
                let body_present = false;
                for (let i = 0; i < kp.length; i++) {
                    if (kp[i].score && kp[i].score > 0.6) {
                        //console.log('Found bodypart #' + i + ' with a score of ' + kp[i].score);
                        body_present = true;
                        break;
                    }
                }
                if (body_present) {
                    //console.log('You are in the picture!');
                    await SystemHelper.awaitState(component, { user_is_on_camera: true });
                    if (component.state.is_paused && !component.state.manually_paused) {
                        component.resume();
                    }
                } else {
                    console.log('Show yourself...');
                    await SystemHelper.awaitState(component, { user_is_on_camera: false });
                    component.pause(false);
                }
            } else {
                console.log('Cant find any pose!');
                await SystemHelper.awaitState(component, { user_is_on_camera: false });
                component.pause(false);
            }
        }

        // Run again after 2000 ms
        if (this.active && this.detector && mirror) {
            setTimeout(() => { this.estimateLoop(component, video) }, this.ESTIMATE_INTERVAL);
        }
    }

    async getSnapshot(video) {
        //console.log('getSnapshot()', video);
        return new Promise(async (resolve, reject) => {
            // If we use webcam video stream
            if (video) {
                resolve(video);
            }
            // Or else we use CameraPreview plugin
            else {
                // eslint-disable-next-line
                CameraPreview.takeSnapshot({}, base64PictureData => {
                    //console.log('Snapshot taken.');
                    //console.log('Setting image source...');

                    const url = 'data:image/jpeg;base64,' + base64PictureData;

                    this.snapshot_image.onload = () => {
                        //console.log('Image loaded.');
                        resolve(this.snapshot_image);
                    };

                    //image.src = url;
                    this.snapshot_image.src = url;
                }, error => {
                    console.log('ERROR', error);
                    reject(error);
                });
            }
        });
    }

    async estimate(video) {
        //console.log('Running estimate()...');
        return new Promise(async (resolve, reject) => {
            try {
                const timestamp = performance.now();
                const poses = await this.detector.estimatePoses(video, this.estimationConfig, timestamp);
                resolve(poses);
            } catch (exc) {
                console.log('ERR', exc);
                reject(exc);
            }
        });
    }

    stop() {
        this.active = false;
        this.detector = null;
    }


    /*
    async initBlur(component, mirror) {
        const net = await bodyPix.load();
      const segmentation = await net.segmentPerson(mirror);

      const backgroundBlurAmount = 9;
      const edgeBlurAmount = 3;
      const flipHorizontal = false;

      const canvas = document.createElement('canvas');
      canvas.style.position = 'absolute';
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      canvas.style.zIndex = 3000;
      document.body.appendChild(canvas);

      setInterval(() => {
        bodyPix.drawBokehEffect(canvas, mirror, segmentation, backgroundBlurAmount, edgeBlurAmount, flipHorizontal);
      }, 1000);
    }
    */

}

export default PoseDetector;