import axios from "axios";
import InstallationHelper from "./InstallationHelper";
import UserHelper from "./UserHelper";
import SystemHelper from "./SystemHelper";

const ApiHelper = {
  Host: null,
  BasePath: null,
  LoginHost: 'https://login.habbie.net',
  //LoginHost: 'https://habbie-login.test',
  // LoginHost: "https://login.stage.habbie.net",

  /**
   * Init the API depending on user's API host
   * @param {Manual API host} value
   */
  Init: async function (value) {
    console.log("Init API...");

    // Update host in storage
    if (value) {
      await SystemHelper.storage.set("host", value);
    }

    let host = value ?? await SystemHelper.storage.get("host");

    this.Host = host;
    this.BasePath = host + "/api";

    console.log("Init API with host:", host);
  },

  /**
   * Get request to the API
   * @param {Endpoint path} path
   * @param {Optionally send token manually} token
   */
  Get: async function (path, token) {
    return new Promise(async (resolve, reject) => {
      axios
        .get(this.BasePath + path, {
          "content-type": "application/json",
          headers: {
            Authorization: `Bearer ${
              token
                ? token
                : await SystemHelper.storage.get("admin_token") === "installation"
                ? await InstallationHelper.token.get()
                : await UserHelper.token.get()
            }`,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            UserHelper.logout();
          }
          reject(error);
        });
    });
  },

  /**
   * Post request to the API
   * @param {Endpoint path} path
   * @param {Data to send} data
   */
  Post: async function (path, data) {
    return new Promise(async (resolve, reject) => {
      axios
        .post(this.BasePath + path, data, {
          "content-type": "application/json",
          headers: {
            Authorization: `Bearer ${
              await SystemHelper.storage.get("admin_token") === "installation"
              ? await InstallationHelper.token.get()
              : await UserHelper.token.get()
            }`,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },

  /**
   * Put request to the API
   * @param {Endpoint path} path
   * @param {Data to send} data
   */
  Put: async function (path, data) {
    return new Promise(async (resolve, reject) => {
      axios
        .put(this.BasePath + path, data, {
          "content-type": "application/json",
          headers: {
            Authorization: `Bearer ${
              await SystemHelper.storage.get("admin_token") === "installation"
              ? await InstallationHelper.token.get()
              : await UserHelper.token.get()
            }`,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },
};

export default ApiHelper;
