import { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { ReactComponent as Loader } from "../../../images/loading-ellipsis.svg";
import QRCode from "react-qr-code";
import ApiHelper from "../../helpers/ApiHelper";
import UserHelper from "../../helpers/UserHelper";
import SystemHelper from "../../helpers/SystemHelper";
import Button from "../../shared/Button";
import bankidLoginHelper, { resolveAfter2Seconds } from "./BankIdLogin";
import { useTranslation } from 'react-i18next';
import "./BankIDLogin.scss";

const BankIDLogin = (props) => {
  const history = props.history;
  const ERROR_MSG = "Ett oväntat fel uppstod. Var god försök igen.";
  const regex =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  const statusLimit = useRef(1);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [method, setMethod] = useState(null);
  const [qrImage, setQrImage] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [device] = useState(
    window?.cordova
      ? "cordova"
      : regex.test(navigator.userAgent)
      ? "mobile"
      : "browser"
  );

  const { t } = useTranslation();

  const failedToLogin = (error) => {
    setStatus("error");
    setError(error);
    setLoading(false);
    setQrImage("");
    setQrCode("");
    statusLimit.current = 0;
  };

  useEffect(() => {
    //loginWithBankId();
    console.log('useEffect');
    if (device === 'browser') {
      initMethod('qr');
    }
    return () => {
      statusLimit.current = 0;
    };
  }, []);

  //   console.log(status);

  const setDefaultSettings = async () => {
    let audio_volume = await SystemHelper.storage.get("audio_volume");
    let reminder_time = await SystemHelper.storage.get("reminder_time");

    if (!audio_volume) {
      await SystemHelper.storage.set("audio_volume", "10");
    }
    if (!reminder_time) {
      await SystemHelper.storage.set("reminder_time", "10:00");
    }
  };

  const getUser = async () => {
    try {
      const user = await UserHelper.getUser();
      UserHelper.name.set(user.first_name);
      setDefaultSettings();
      setLoading(false);

      // Run onboarding if user hasn't seen it yet
      if (await SystemHelper.storage.get("onboarding_finished_" + user.uuid)) {
        history.push("/preload");
      } else {
        history.push("/onboarding");
      }
    } catch (error) {
      console.error("User not found->", error);
      UserHelper.logout(history);
      setLoading(false);
    }
  };

  /**
   * Save token and server host in local storage
   * @param {The user token} token 
   * @param {The API server URL} server_url 
   */
  const setAuthData = async (token, server_url) => {
    // Init API with user's API host
    ApiHelper.Init(server_url);

    // Save token and code in local storage
    await UserHelper.token.set(token);
    
    await getUser();
  };

  /**
   * Checks for BankID login status
   * @param {BankID order reference} order_ref
   */
  const checkStatus = async (order_ref) => {
    if (statusLimit.current === 0) return null;

    await resolveAfter2Seconds();

    const { data, error } = await bankidLoginHelper.checkStatusBankId(
      order_ref
    );

    // Ff error or no data, return null
    if (error) {
      console.log("checkStatus:error", error);

      failedToLogin(
        error.message && error.message.length > 0 ? error.message : ERROR_MSG
      );
      return null;
    }

    if (statusLimit.current === 0) return null;

    // If hint code is userSign, means user scanned QR code but not singed
    if (data.hint_code === "userSign") {
      setStatus("userSign");
      setLoading(false);
    }

    // If status timeout then the  process will restart
    if (data.status === "timeout") {
      await loginWithBankId();
      return null;
    }

    // If user verified, then we get token and user type
    if (data.token) {
      setLoading(true);
      setAuthData(data.token, data.server_url);
      return null;
    }

    // If user not scanned QR code, then we will restart the process
    if (statusLimit.current < Date.now() && data.hint_code !== "userSign") {
      await loginWithBankId();
      return null;
    }

    // Re-run the cbeck
    checkStatus(order_ref);
  };

  /**
   * Get BankID QR code
   * @param {BankID order reference} order_ref 
   * @returns 
   */
  const getQrCodeImage = async (order_ref) => {
    const { data, error } = await bankidLoginHelper.qrCodeImageBankId(
      order_ref
    );

    if (error) {
      failedToLogin(
        error.message && error.message.length > 0 ? error.message : ERROR_MSG
      );
      return null;
    }

    if (!data.qr_image && !data.qr_string) {
      failedToLogin(ERROR_MSG);
      return null;
    }

    setQrImage(data.qr_image ?? "");
    setQrCode(data.qr_string ?? "");
    setLoading(false);

    statusLimit.current = Date.now() + 10000; // check status for 10 seconds

    setTimeout(() => {
      checkStatus(order_ref);
    }, 2000);

  };

  /**
   * Abort and go back to login selection
   */
  const abort = () => {
    statusLimit.current = 0;
    history.push("/login");
  };

  /**
   * Opens native BankID app with Cordova plugin
   * @param {BankID autostart token} auto_start_token 
   */
  const openBankId = (auto_start_token) => {
    if (window?.cordova && auto_start_token.length > 0) {

      window?.plugins?.bankidLogin(
        {
          token: auto_start_token,
          redirectUrl: device === "mobile" ? "null" : "habbiapp://",
        },
        (response) => {
          //setLoading(false);

          console.log("bankidLogin:response", response);
        },
        (error) => {
          setLoading(false);

          console.log("bankidLogin:error", error);
        }
      );
    }
  };

  /**
   * Initiates the BankID login process
   * @param {Selected login method (QR or native app)} method
   */
  const loginWithBankId = async (method) => {
    if (status === "error" && statusLimit.current === 0) {
      setStatus("");
      setError("");
      return null;
    }
    if (statusLimit.current === 0) return null;
    if (statusLimit.current === 1) setLoading(true);

    const { data, error } = await bankidLoginHelper.initiate();
    if (error) {
      console.log("bankIdLogin:error", error);
      // setError(error.message);
      failedToLogin(
        error.message && error.message.length > 0 ? error.message : ERROR_MSG
      );
      return null;
    }

    if (!data.order_ref) {
      failedToLogin(ERROR_MSG);
      return null;
    }

    // Open native app of running native with Cordova
    if (method === 'native' && device === "cordova") {
      if (!data.auto_start_token) {
        setStatus("error");
        setError("Ett oväntat fel uppstod. Var god försök igen.");
        setLoading(false);
        return null;
      }
      setLoading(true);
      console.log('open bankid native');
      openBankId(data.auto_start_token);
      statusLimit.current = Date.now() + 10000; // check status for 10 seconds
      setTimeout(() => {
        checkStatus(data.order_ref);
      }, 5000);
      return null;
    }
    // Open native app through browser if running in mobile browser
    else if (method === 'native' && device === "mobile") {
      if (!data.auto_start_token) {
        setStatus("error");
        setError("Ett oväntat fel uppstod. Var god försök igen.");
        setLoading(false);
        return null;
      }
      console.log('open bankid in browser');
      window.location.href = `https://app.bankid.com/?autostarttoken=${data.auto_start_token}&redirect=null`;
      statusLimit.current = Date.now() + 10000; // check status for 10 seconds
      setTimeout(() => {
        checkStatus(data.order_ref);
      }, 5000);
      return null;
    } else {
      console.log('No method or device');
    }

    getQrCodeImage(data.order_ref);
  };

  /**
   * Initiate method (qr/native)
   * @param {Selected login method} method 
   */
  const initMethod = (method) => {
    console.log('set method', method);
    setMethod(method);
    loginWithBankId(method);
  };

  return (
    <section id="bankID-view" className="blobs">
      {isLoading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      <div className="card">
        <div className="container">
          {method !== 'qr' && method !== 'native' && (
            <div className="button-rows">
              <div><Button className="blue-dark" style={{ width: '100%' }} onClick={ () => initMethod('native') }>{ t('login:bankid.bankid_on_this_device') }</Button></div>
              <div style={{ marginBottom: 0 }}><Button className="blue-dark" style={{ width: '100%' }} onClick={ () => initMethod('qr') }>{ t('login:bankid.bankid_on_another_device') }</Button></div>
            </div>
          )}
          {error.length > 0 && (
            <>
              <div className="error">{error}</div>
              <Button
                className="blue-dark"
                onClick={() => {
                  statusLimit.current = 1;
                  setStatus("");
                  setError("");
                  loginWithBankId();
                }}
              >
                { t('common:try_again') }
              </Button>
            </>
          )}
          {method === 'qr' &&
            status !== "userSign" &&
            error.length <= 0 &&
            !isLoading && (
              <h3>{ t('login:bankid.scan_instruction') }</h3>
            )}

          {method === 'qr' && status === "userSign" && (
            <>
              <h3>{ t('login:bankid.verify_instruction') }</h3>
            </>
          )}

          {method === 'qr' && status !== "userSign" &&
            (qrCode.length > 0 ? (
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "240", width: "100%" }}
                value={qrCode}
                viewBox={`0 0 256 256`}
              />
            ) : qrImage.length > 0 ? (
              <img src={qrImage} alt="" />
            ) : null)}
        </div>
      </div>
      <div className="button-bar bottom">
        <Button className="yellow-light auto-width" onClick={abort}>
          { t('common:back') }
        </Button>
      </div>
    </section>
  );
};
export default withRouter(BankIDLogin);
