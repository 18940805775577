class PermissionsChecker {

    constructor(window) {
        if (window.device) {
            this.platform = window.device.platform;
        } else {
            this.platform = 'browser';
        }
    }

    /**
     * Check permissions for app
     */
    check() {
        //Android permissions
        if (this.platform === 'Android') {
            const permissions = window.cordova.plugins.permissions;

            var list = [
                permissions.CAMERA,
                //permissions.ACCESS_FINE_LOCATION,
                //permissions.BLUETOOTH,
                //permissions.BLUETOOTH_SCAN,
                //permissions.BLUETOOTH_CONNECT,
                permissions.RECORD_AUDIO,
                permissions.INTERNET,
                //permissions.READ_EXTERNAL_STORAGE,
                //permissions.WRITE_EXTERNAL_STORAGE
              ];

            function error(status) {
                alert('Behörigheter till enheten saknas. Gå till inställningar och godkänn alla behörigheter.');
            }
            
            function success (status) {
                if (!status.hasPermission) {
                    permissions.requestPermissions(
                        list,
                        function(status) {
                            if( !status.hasPermission ) error(status);
                        },
                        error
                    );
                }
            }

            permissions.hasPermission(list, success, null);
        }
    }

}

export default PermissionsChecker;