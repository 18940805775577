import React, { Component } from 'react';
import i18next from 'i18next';

class ExerciseProgress extends Component {
    
    render() {

        const { t } = this.props.parent.props;
        let i = 0;

        if (this.props.exercises) {
            return (
                <div className="progress-bar left top full-width">
                    {this.props.evaluation === 'workout' ?
                        <div className="main-caption">{ t('workout:all_exercises_finished') }</div>
                    : ''}
                    <div className="progress">            
                    {( this.props.exercises.map((ex) => {
                        const is_current = (i === this.props.currentIndex);

                        // Get locale exercise name
                        if (ex.names && ex.names[i18next.language]) {
                            ex.name = ex.names[i18next.language];
                        }

                        i++;
                        return (     
                            <div className={(is_current && this.props.evaluation !== 'workout' ? 'item current' : 'item') + ((i <= this.props.currentIndex + 1 && this.props.evaluation === 'exercise') || this.props.evaluation === 'workout' ? ' blue-dark' : '')} key={'progress-item-' + i}>
                                {( is_current && this.props.evaluation !== 'workout' ? <div className="caption">{ex.name} {(ex.sets > 1 ? this.props.currentSet + '/' + ex.sets : '')}</div> : '' )}
                                <div className={'bar' + (this.props.evaluation ? ' white' : '')}>
                                    { is_current ? 
                                        <div className="time" style={{width: (this.props.progress < 1 ? this.props.progress * 100 : 100) + '%'}}></div>
                                    : '' }
                                </div>
                            </div>
                        );
                    }))}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default ExerciseProgress;