import React, { useEffect } from 'react';
import SystemHelper from '../helpers/SystemHelper';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Button from './../shared/Button';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { SkeletonTheme } from 'react-loading-skeleton';
import './IncomingCall.scss';
import ApiHelper from '../helpers/ApiHelper';

const IncomingCall = () => {
    const history = useHistory();
    const { room_id } = useParams();
    const location = useLocation();
    const { t } = useTranslation(['common', 'meeting']);
    const data = location.state || {};
    let alarm;

    useEffect(() => {
        // Play alarm sound when component mounts
        let file = SystemHelper.getSystemPath('/assets/audio/reminder-alarm.mp3');
        alarm = new Audio(file);
        alarm.volume = 1;
        alarm.loop = true;
        alarm.play();

        if (window.cordova?.plugins?.brightness) {
            const brightness = window.cordova.plugins.brightness;
            brightness.setBrightness(1, () => {
            }, (error) => {
                console.error(error);
            });
        }

        // Cleanup when component unmounts
        return () => {
            alarm.pause();
            alarm = null;
        };
    }, []);

    const decline = async () => {
        ApiHelper.Post(`/video-calls/${ room_id }/decline`).then(response => {
            console.log('response', response);
            history.push('/dashboard');
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        
        <div>
            <SkeletonTheme color="#eecbc5" highlightColor="#eed9d6">
                <section id="view-incoming-call" className="blobs-light">
                    <div className="wrapper middle">
                        <h1>{data.admin_name}<br />{ t('meeting:call.calling') }...</h1>
                        { data.admin_image &&
                            <div className="portrait-container">
                                <img src={data.admin_image} alt="" />
                            </div>
                        }
                        { data.admin_title &&
                            <h3>{t('common:' + data.admin_title)}</h3>
                        }
                        <h3>{data.organisation_name}</h3>
                    </div>

                    <div className="button-bar bottom">
                        <Button className="red" Icon="phone-off" onClick={ decline }>{ t('meeting:call.decline') }</Button>
                        <Button className="green-dark" Icon="phone-on" onClick={() => history.push(`/meeting/${room_id}`)}>{ t('meeting:call.answer') }</Button>
                    </div>
                </section>
            </SkeletonTheme>
        </div>
    );
};

export default withTranslation(['common', 'meeting'])(IncomingCall);
