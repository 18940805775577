import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import ApiHelper from './helpers/ApiHelper';
import UserHelper from './helpers/UserHelper';
import SystemHelper from './helpers/SystemHelper';
import Button from './shared/Button';
import './login/NumericLogin.scss';

class StaffCodeVerification extends Component {

    constructor(props) {
        super(props);
        this.history = this.props.history;
        this.code_length = 6;
        this.state = {
            input: '',
            visible_input: this.fillBullets(''),
            error: '',
            show_ui: false
        }
    }

    async setDefaultSettings() {
        let audio_volume = await SystemHelper.storage.get('audio_volume');
        let reminder_time = await SystemHelper.storage.get('reminder_time');

        if (!audio_volume) {
            await SystemHelper.storage.set('audio_volume', '10');
        }
        if (!reminder_time) {
            await SystemHelper.storage.set('reminder_time', '10:00');
        }
    }

    login() {

        console.log('login()');

        let admin_code;
        // Get admin code from input or login (previous view)
        if (this.props.location.admin_code) {
            admin_code = this.props.location.admin_code;
        } else {
            admin_code = this.state.input;
        }

        // Get translation
        const t = i18next.getFixedT(null, 'login', null);

        // Control code and remove from DB
        UserHelper.verifyStaffCode(admin_code, this.props.location.code).then(async (res) => {

            // Reject if no data has been returned
            if (!res.data.data) {
                Promise.reject('Ingen admin token!');
            }

            // Store admin token in local storage
            await SystemHelper.storage.set('admin_token', res.data.data.admin_token);

            // Start standard login flow
            UserHelper.login(this.props.location.code, res.data.data.admin_token).then(async (res) => {
                // Save token and code in local storage
                await UserHelper.token.set(res.data.data.token);
                UserHelper.code.set(this.props.location.code);

                console.log('User logged in:', res);

                // Init API with user's API host
				ApiHelper.Init(res.data.data.server_url);

				// Get logged in user
                if (await SystemHelper.storage.get('admin_token') !== 'installation') {
                    UserHelper.getUser().then(async (user) => {
                        console.log('USER', user);
                        UserHelper.name.set(user.first_name);
                        this.setDefaultSettings();
                        // Run onboarding if user hasn't seen it yet
                        if (await SystemHelper.storage.get('onboarding_finished_' + user.uuid)) {
                            this.history.push('/preload');
                        } else {
                            this.history.push('/onboarding');
                        }
                    });
                } else {
                    UserHelper.name.set("installation");
                    this.setDefaultSettings();

                    // Set installation locale
                    await SystemHelper.storage.set('locale', res.data.data.locale);
                    i18next.changeLanguage(res.data.data.locale);

                    this.history.push('/dashboard-installation');
                }
            }).catch((error) => {
                // Show error
                console.error('error', error);
                this.setState({ input: '', visible_input: this.fillBullets(''), error: t('login:numeric.login.wrong_code') });
            });

        }).catch((error) => {
            console.error('error', error);
            this.setState({ input: '', visible_input: this.fillBullets(''), error: t('login:numeric.login.wrong_code') });
        });

        // Continue with the standard user login
    }

    fillBullets(input) {
        let bullets = '';
        for (let i = 0; i < this.code_length - input.length; i++) {
            bullets += '•';
        }
        return input + bullets;
    }

    clickNum(num) {
        this.setState({ error: '' });
        if (this.state.input.length < this.code_length) {
            this.setState(prevState => { return { input: prevState.input + num }}, function() {
                console.log('input', this.state.input);

                this.setState({ visible_input: this.fillBullets(this.state.input) });
            });
        }
    }

    backspace() {
        if (this.state.input.length > 0) {
            this.setState(prevState => { return { input: prevState.input.substr(0, prevState.input.length - 1) }}, function() {
                this.setState({ visible_input: this.fillBullets(this.state.input) });
            });
        }
    }

    handleKeyPress(component, event) {
        if (event) {
            if (!isNaN(event.key)) {
                component.clickNum(event.key);
            }
            else if (event.key === 'Backspace') {
                component.backspace();
            } else if (event.key === 'Enter') {
                component.login();
            }
        }
    }

    componentDidMount() {
        // Verify directly if admin_code is past from previous view
        if (this.props.location.admin_code) {
            this.login();
        } else {
            this.setState({ show_ui: true });
        }

        // Listen for keyboard events
        document.addEventListener('keydown', (event) => { this.handleKeyPress(this, event) });
    }

    render() {

        const { t } = this.props;

        return (
            <section id="view-login" className="white">

            { this.state.show_ui ?
                <div>
                    <div className="header blobs-light">
                        <div id="login-input">{ this.state.visible_input }</div>
                    </div>
                    <h3>{ t('login:numeric.verification.heading') }</h3>
                    <p>{ t('login:numeric.verification.instruction') }</p>
                    <div className="numpad">
                        <div className="row">
                            <Button className="circle numpad-number" onClick={ () => this.clickNum('1')}>1</Button>
                            <Button className="circle numpad-number" onClick={ () => this.clickNum('2')}>2</Button>
                            <Button className="circle numpad-number" onClick={ () => this.clickNum('3')}>3</Button>
                        </div>
                        <div className="row">
                            <Button className="circle numpad-number" onClick={ () => this.clickNum('4')}>4</Button>
                            <Button className="circle numpad-number" onClick={ () => this.clickNum('5')}>5</Button>
                            <Button className="circle numpad-number" onClick={ () => this.clickNum('6')}>6</Button>
                        </div>
                        <div className="row">
                            <Button className="circle numpad-number" onClick={ () => this.clickNum('7')}>7</Button>
                            <Button className="circle numpad-number" onClick={ () => this.clickNum('8')}>8</Button>
                            <Button className="circle numpad-number" onClick={ () => this.clickNum('9')}>9</Button>
                        </div>
                        <div className="row">
                            <Button className="circle numpad-number" onClick={ () => this.clickNum('0')}>0</Button>
                            <Button className="backspace" onClick={ () => this.backspace()}></Button>
                        </div>
                    </div>
                    <div className="error">{ this.state.error }</div>
                    <div className="button-bar bottom">
                        <Button className="yellow-light" IconLeft="arrow-left" onClick={() => this.history.push('/login')}>{ t('login:numeric.verification.back') }</Button>
                        <Button className="blue-dark" Icon="arrow-right" onClick={() => this.login()}>{ t('login:numeric.verification.login') }</Button>
                    </div>
                </div>
          : ''}
          </section>
        );
    }
}

export default withRouter(withTranslation(['login'])(StaffCodeVerification));
