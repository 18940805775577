import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import DataHelper from '../helpers/DataHelper';
import Button from '../shared/Button';
import ApiHelper from '../helpers/ApiHelper';
import UserHelper from '../helpers/UserHelper'
import FileHelper from '../helpers/FileHelper';
import './Voice.scss';

class Voice extends Component {

    constructor(props) {
        super(props);
        this.history = this.props.history;
        this.state = {
            user: null,
            view: null,
            config: null,
        }

        this.recording = {
            recorder: null, 
            media: null,
            timer: null, 
            url: null,
            limit_secs: 60, 
            blob: null,
            is_sent: false,
            is_recording: false,
            is_playing: false,
            chunks: [],
            play_audio: null,
            animation: null
        };

        this.config = null;
        this.idle_timeout = null;
        this.after_render = false;
        
    }

    /**
     * Get suser
     */
    async loadUser() {
        await UserHelper.getUser().then((usr) =>{
            this.setState({user:usr});
        });
    }

    /**
     * Prepare audio recording
     */
    prepareAudio() {
        // Stop playing audio if playing
        this.stopPlayRecording();
        
        // Reset chunk array
        this.recording.chunks = [];

        this.setState({ view: 'recording' });
    }

    /**
     * Start recording countdown
     */
    startCountdown() {
        console.log('Countdown started');
        let secs = 0;

        if (this.recording.timer) {
            clearInterval(this.recording.timer);
        }

        const component = this;
        this.recording.timer = setInterval(() => {
            secs++;
            if (secs > component.recording.limit_secs) {
                component.stopRecording();
                console.log('Countdown stopped');
            }
        }, 1000);
    }

    /**
     * Setup and start the recording
     */
    async initAudioAndRecord() {
        console.log('initAudioAndRecord()');
        const component = this;

        if (this.recording.is_recording) {
            return;
        } else {
            this.recording.is_recording = true;
        }

        this.recording.is_sent = false;
        
        // Set view
        this.setState({ view: 'recording-active' });

        // iOS
        if (this.isIos()) {

            // Use m4a format for iOS
            this.recording.src = 'recording.m4a';

            // Use Media plugin for iOS

            // eslint-disable-next-line
            this.recording.media = new Media(this.recording.src, () => {
                console.log("recordAudio():Audio Success");

                // Start countdown
                this.startCountdown();

            }, (err) => {
                console.log("recordAudio():Audio Error: "+ err.code);
                this.recording.is_recording = false;
            });

            console.log('Starting recording...');

            this.recording.media.startRecord();

            this.initAmplitude();

        }
        // Android and browser
        else {

            this.recording.src = 'recording.aac';

            console.log('Recording to recording.aac');


            try {
                // Use browser user media
                navigator.getUserMedia = navigator.getUserMedia ||
                                navigator.webkitGetUserMedia ||
                                navigator.mozGetUserMedia;

                // Get user microphone
                navigator.mediaDevices.getUserMedia({ audio: true}).then(stream => {

                    console.log('Running getUserMedia...');

                    this.initAmplitude(stream);

                    // Init recorer
                    this.recording.recorder = new MediaRecorder(stream);

                    // Push chunks when data available
                    this.recording.recorder.addEventListener('dataavailable', event => {
                        component.recording.chunks.push(event.data);
                    });

                    // Finish recording at stop
                    component.recording.recorder.addEventListener('stop', () => { component.finishRecording() });

                    // Start the recorder
                    component.recording.recorder.start();

                    console.log('Recorder started');

                    // Start the countdown
                    this.startCountdown();

                });
            } 
            catch (err) {
                console.error('Error using user media:', err);
                this.recording.is_recording = false;
            }
        }
    }
    
    /**
     * Stop the recording
     */
    stopRecording() {

        console.log('Recorder stopped');

        // Stop the recording
        if (this.recording.media) {
            this.recording.media.stopRecord();
        }
        if (this.recording.recorder) {
            this.recording.recorder.stop();
        }
        
        this.recording.is_recording = false;

        // Clear the countdown interval
        if (this.recording.timer) {
            clearInterval(this.recording.timer);
            this.recording.timer = null;
        }

        this.finishRecording()

        // Cancel animation (for Android/browser)
        if (!this.isIos() && this.recording.animation) {
            clearInterval(this.recording.animation);
        }
    }

    isIos() {
        return (window.device && window.device.platform === 'iOS');
      }

    /**
     * Finish and prepare for playback and sending
     */
    finishRecording() {

        if (this.isIos()) {
            console.log('Getting file...', this.recording.src);
            // Use temporary filesystem
            FileHelper.get(this.recording.src, window.TEMPORARY).then(fileEntry => {
                fileEntry.file(file => {
                    console.log('File size', file.size);
                    console.log('File name', file.name);
                    console.log('File type', file.type);
                    this.recording.blob = file;
                });
            }).catch(err => {
                console.log('Fel blev det:');
                console.log(err);
            });
        }
        else {
             // Make blob from chunks
             this.recording.blob = new Blob(this.recording.chunks);

             // Create URL from blob
             this.recording.url = URL.createObjectURL(this.recording.blob);
 
             // Create Audio for listening
             this.recording.play_audio = new Audio(this.recording.url);
        }

        // Change view
        this.setState({ view: 'recording-done' });


    }

    /**
     * Play the recording
     */
    playRecording() {
        if (this.recording.play_audio) {
            this.recording.play_audio.currentTime = 0;
            this.recording.play_audio.play();
        } else if (this.recording.media) {
            this.recording.media.play();
        } else {
            alert('Ingen inspelning tillgänglig. Försök igen.');
        }
    }

    /**
     * Stop playing the recording
     */
    stopPlayRecording() {
        if (this.recording.play_audio) {
            this.recording.play_audio.pause();
            this.recording.play_audio.currentTime = 0;
        }

        if (this.recording.media) {
                this.recording.media.stop();
        }
    }
    
    /**
     * Send the recording to server
     */
    async sendRecording() {

        this.setState({ view: 'sending' });

        // Stop playing audio if playing
        this.stopPlayRecording();

        const component = this;
        
        let token = await UserHelper.token.get();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);

        var formdata = new FormData();
        formdata.append("file", this.recording.blob);
        
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        
        fetch(ApiHelper.BasePath + '/voice-messages', requestOptions)
        .then(response => response.text())
        .then(result => { 
            console.log(JSON.stringify(result));
            component.recording.is_sent = true;
            //component.deleteRecording();
            component.setState({view:'thanks'});
        })
        .catch(error => {
            alert('Ett fel uppstod och inspelningen kunde inte skickas. Vänligen kontakta din vårdgivare på annat sätt.')
            console.log('Fel från API va');
            console.log('error', error)
        });  
    }

    /**
     * Animate the amplitude
     * @param {Incoming amplitude from microphone} amplitude 
     */
    animateAmplitude(amplitude) {
        let meter = document.querySelectorAll('.recording-meter .layer');
        let i = 1;
        meter.forEach(layer => {
            let rnd = Math.random();
            layer.style.transform = 'scale(' + (1 + amplitude * (1 + rnd / 10) * (i / 2)) + ')';
            i++;
        });
    }

    /**
     * Init getting amplitude from recording
     * @param {The audio stream (not in iOS)} stream 
     */
    initAmplitude(stream) {

        console.log('initAmplitude()');

        // Android and browser
        if (stream) {
            console.log('Using stream...');
            const audioContext = new AudioContext();
            const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
            const analyserNode = audioContext.createAnalyser();
            mediaStreamAudioSourceNode.connect(analyserNode);

            window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
                    window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

            window.cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;
            
            const pcmData = new Float32Array(analyserNode.fftSize);
            let amplitude = 0;

            // Get amplitude
            this.recording.animation = () => {
                analyserNode.getFloatTimeDomainData(pcmData);
                let sumSquares = 0.0;
                for (const amplitude of pcmData) {
                    sumSquares += amplitude * amplitude;
                }
                amplitude = Math.sqrt(sumSquares / pcmData.length);
                console.log('amp', amplitude);
                this.animateAmplitude(amplitude);

                // Run again as long as recording
                if (this.recording.is_recording) {
                    setTimeout(this.recording.animation, 60);
                }
            };
        }
        // iOS
        else {
            console.log('Using Media plugin...');
            this.recording.animation = () => {
                // Get amplitude
                this.recording.media.getCurrentAmplitude((amplitude) => {
                    console.log('amp', amplitude);
                    this.animateAmplitude(amplitude);

                    // Run again as long as recording
                    if (this.recording.is_recording) {
                        setTimeout(this.recording.animation, 60);
                    }
                }, (error) => {
                    console.log('Error on amplitude', error);
                });
            }
        }

        this.recording.animation();
        console.log('start', this.recording.animation);
    }

    setBeContactedState() {
        // Post ping to admin
        ApiHelper.Post('/pings');
        this.setState({ view: 'be-contacted' });
    }

    abort() {
        this.setState({ view: 'intro' });
    }

    componentWillUnmount() {
        // Clear intervals
        if (this.idle_timeout) {
            clearInterval(this.idle_timeout);
        }
        if (this.recording.timer) {
            clearInterval(this.recording.timer);
        }
    }

    async componentDidMount() {
        await this.loadUser();

        await DataHelper.get('config').then((config) => {
            this.config = config;
            this.setState({ config: config });
            this.setState({ view:'intro' });
        });

        // Set idle timeout
        // Go to dashboard
        this.idle_timeout = setTimeout(() => {
            this.history.push('/dashboard');
        }, this.config.idle_timeout_minutes * 60000);

    }

    render() {

        const { t } = this.props;

        return (
            <section id="view-voice" className="white slide-up">
                <div>
                    <div className="content middle autoz">
                        {this.state.view === 'intro' ? (
                            <div className="wrapper">
                                <h2>{ t('voice_message:intro.heading') }</h2>
                                <div className="button-rows">
                                    <div><Button className="blue-dark" Icon="thumb-up" onClick={() => this.prepareAudio()}>{ t('voice_message:intro.buttons.yes') }</Button></div>
                                    <div><Button className="blue-light" onClick={() => this.setBeContactedState()}>{ t('voice_message:intro.buttons.be_contacted') }</Button></div>
                                    <div><Button className="yellow-light" onClick={() => this.history.push('/workout/done')}>{ t('voice_message:intro.buttons.no') }</Button></div>
                                </div>
                            </div>
                        ) : this.state.view === 'be-contacted' ? (
                            <div className="wrapper">
                                <h2>{ t('voice_message:be_contacted.you_will_be_contacted') }</h2>
                                <div className='button-rows' >
                                    <div><Button className="blue-dark" Icon="arrow-down" onClick={() => this.history.push('/workout/done')}>{ t('voice_message:be_contacted.ok') }</Button></div>
                                </div>
                            </div>                            
                        ) : this.state.view === 'recording' ? (
                            <div className="wrapper">
                                <h2>{ t('voice_message:message.heading') }</h2>
                                <div className="button-rows">
                                    <div><Button className="blue-dark" Icon="mic-white" onClick={() => this.initAudioAndRecord()}>{ t('voice_message:message.start') }</Button></div>
                                    <div><Button className="yellow-light" onClick={() => this.setState({ view: 'recording-done' })}>{ t('voice_message:message.back') }</Button></div>
                                </div>
                            </div>
                        ) : this.state.view === 'recording-active' ? (
                            <div className="wrapper">
                                <div className="recording-meter top">
                                    <div className="layer layer-4"></div>
                                    <div className="layer layer-3"></div>
                                    <div className="layer layer-2"></div>
                                    <div className="layer layer-1"></div>
                                </div>
                                <h2>{ t('voice_message:message.recording_active.recording') }</h2>
                                <div className="button-rows">
                                    <div><Button className="blue-light" Icon="mic-blue" onClick={() => this.stopRecording()}>{ t('voice_message:message.recording_active.stop_recording') }</Button></div>
                                </div>
                            </div>
                        ) : this.state.view === 'recording-done' ? (
                            <div>
                                <div className="wrapper">
                                    <h2>{ t('voice_message:message.recording_done.heading') }</h2>
                                    <div className="button-rows">
                                        <div><Button className="white" Icon="play-blue" onClick={() => this.playRecording()}>{ t('voice_message:message.recording_done.listen_to_recording') }</Button></div>
                                        <div><Button className="blue-light" Icon="reload" onClick={() => this.prepareAudio()}>{ t('voice_message:message.recording_done.redo_recording') }</Button></div>
                                    </div>                            
                                </div>
                                <div className="button-bar bottom">
                                    <Button className="yellow-light" onClick={() => this.abort()}>{ t('voice_message:message.recording_done.cancel') }</Button>
                                    <Button className="blue-dark" Icon="arrow-right" onClick={() => this.sendRecording()}>{ t('voice_message:message.recording_done.send_recording') }</Button>
                                </div> 
                            </div>  
                        ) : this.state.view === 'sending' ? (
                            <div className="wrapper">
                                <h2>{ t('voice_message:message.sending.sending_recording') }</h2>
                            </div>    
                        ) : this.state.view === 'thanks' ? (
                            <div className="wrapper">
                                <h1>{ t('voice_message:message.thanks.thanks') }</h1>
                                <h3>{ t('voice_message:message.thanks.message_received') }</h3>
                                <div className="button-rows">
                                    <Button className="blue-dark" Icon="arrow-down" onClick={() => this.history.push('/dashboard')}>{ t('voice_message:message.thanks.ok') }</Button>
                                </div>
                            </div>                             
                         ) : '' }
                    </div>
                </div>
            </section>  
        );
    }
}

export default withRouter(withTranslation(['voice_message', 'common'])(Voice));