import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import App from "./App";
import i18next from "i18next";
import Translations from "./i18n";
import "./index.css";
//import UpdateApp from './components/UpdateApp';
import Dashboard from "./components/Dashboard";
import DashboardInstallation from "./components/DashboardInstallation";
import LoginType from "./components/LoginType";
import Onboarding from "./components/Onboarding";
import Preload from "./components/Preload";
import StaffCodeVerification from "./components/StaffCodeVerification";
import SystemHelper from "./components/helpers/SystemHelper";
import BankIDLogin from "./components/login/BankIDLogin";
import NumericLogin from "./components/login/NumericLogin";
import Settings from "./components/settings/Settings";
import Voice from "./components/voice/Voice";
import Evaluation from "./components/workout/Evaluation";
import Exercise from "./components/workout/Exercise";
import Meeting from "./components/meeting/Meeting";
import IncomingCall from "./components/meeting/IncomingCall";
import WorkoutIntro from "./components/workout/WorkoutIntro";
import * as serviceWorker from "./serviceWorker";
import { WherebyProvider } from "@whereby.com/browser-sdk/react";
import { MeetingProvider } from "./components/meeting/MeetingProvider";
import ApiHelper from "./components/helpers/ApiHelper";

// Is user authenticated
const isAuth = async () => {
  return (
    await SystemHelper.storage.get('token') !== null &&
    await SystemHelper.storage.get("admin_token") !== "installation"
  );
};

// Is installation authenticated
const isAuthInstallation = async () => {
  return (
    await SystemHelper.storage.get('token') !== null &&
    await SystemHelper.storage.get("admin_token") === "installation"
  );
};

// Redirect based on auth
const Redir = (isAuth, isAuthInstallation) => {
    isAuth || isAuthInstallation ? (
      window.location.pathname.length === 1 ? ( // Go to dashboard if no path
        isAuthInstallation ? (
          <Redirect to="/dashboard-installation" />
        ) : (
          <Redirect to="/dashboard" />
        )
      ) : (
        <Redirect to={window.location.pathname} />
      )
    ) : (
      <Redirect to="/login" />
    )
};

const startApp = async () => {

  // Init IndexedDB
  await SystemHelper.storage.init().catch(error => {
    console.log('Storage init error', error);
    window.location.reload();
;  });

  // Migrate from local storage (1.6.12)
  await SystemHelper.storage.migrateFromLocalStorage();

  // Init API
  ApiHelper.Init();

  // Init translations
  Translations.init();

  // Get locale if in installation
  if (await isAuthInstallation()) {
    if (await SystemHelper.storage.get('locale')) {
      i18next.changeLanguage(await SystemHelper.storage.get('locale'));
    }
  }

  // Run native stuff
  if (window.cordova) {
    // Hide statusbar
    // eslint-disable-next-line
    StatusBar.hide();

    // Screen always on and start with bright
    const brightness = window.cordova?.plugins?.brightness;
    if (brightness) {
      brightness.setBrightness(
        1,
        () => {},
        (error) => {
          console.error(error);
        }
      );

      // Reset keep screen alive every 10 s
      // iOS seem to reset idleTimerDisabled when using camera
      if (window.device.platform === "iOS") {
        setInterval(() => {
          const brightness = window.cordova.plugins.brightness;
          brightness.setKeepScreenOn(
            true,
            () => {
              console.log("Keep screen on again");
            },
            (err) => {
              console.log("ERROR", err);
            }
          );
        }, 10000);
      }
      // Else just set it once
      else {
        brightness.setKeepScreenOn(
          true,
          () => {
            console.log("Keep screen on once");
          },
          (err) => {
            console.log("ERROR", err);
          }
        );
      }

      // Set brightness to max on app resume
      document.addEventListener(
        "resume",
        () => {
          const brightness = window.cordova?.plugins?.brightness;
          if (brightness) {
            console.log("Setting brightness to max");
            brightness.setBrightness(
              1,
              () => {
                console.log("success");
              },
              (error) => {
                console.error(error);
              }
            );
          }
        },
        false
      );

      // Set brightness to system default on app pause
      document.addEventListener(
        "pause",
        () => {
          const brightness = window.cordova?.plugins?.brightness;
          if (brightness) {
            console.log("Setting brightness to system default");
            brightness.setBrightness(
              -1,
              () => {
                console.log("success");
              },
              (error) => {
                console.error(error);
              }
            );
          }
        },
        false
      );
    } else {
      console.error("Could not use brightness!");
    }

    // Set Android fullscreen
    if (window.device.platform === "Android") {
      // eslint-disable-next-line
      AndroidFullScreen.immersiveMode(
        () => {
          console.log("Fullscreen set");
        },
        () => {
          console.error("Fullscreen error");
        }
      );

      // Set CSS class 'android'
      document.getElementsByTagName("body").className = "android";
    } else if (window.device.platform === "iOS") {
      // Set CSS class
      document.getElementsByTagName("body").className = "ios";
    }

    // Get app version
    window.cordova.getAppVersion.getVersionNumber().then((version) => {
      // Init Bugsnag
      Bugsnag.start({
        apiKey: "744d605c49889adf6f814a9045e72a21",
        plugins: [new BugsnagPluginReact()],
        appVersion: version,
      });
    });

    // Use native alerts
    // eslint-disable-next-line
    alert = navigator.notification.alert;

    SystemHelper.INTERNET_WAS_INTERRUPTED = false;

    document.addEventListener("offline", onOffline, false);
    document.addEventListener("online", onOnline, false);

    function onOffline() {
      SystemHelper.INTERNET_WAS_INTERRUPTED = true;
      alert(
        "Habbie har ingen internetanslutning och fungerar inte förrän en anslutning har skapats. Anslut till ett nätverk i inställningarna på din enhet."
      );
    }
    function onOnline() {
      if (SystemHelper.INTERNET_WAS_INTERRUPTED) {
        SystemHelper.INTERNET_WAS_INTERRUPTED = false;
        alert(
          "Habbie har tilldelats en internetanslutning och ska nu fungera."
        );
      }
    }
  }

  const container = document.getElementById('root');
  const root = createRoot(container);

  (async () => {
    const auth = await isAuth();
    const authInstallation = await isAuthInstallation();

    // Render the router
    root.render(
      // Remove for Google Play due to policy update
      //<Route path="/update-app" component={UpdateApp} />
      <Suspense fallback={false}>
        <HashRouter>
          <WherebyProvider>
            <MeetingProvider>
              <Switch>
                <Route path="/login/numeric" component={NumericLogin} />
                <Route path="/login/bankid" component={BankIDLogin} />
                <Route path="/login" component={LoginType} />
                <Route path="/onboarding" component={Onboarding} />
                <Route path="/dashboard" component={Dashboard} />
                <Route
                  path="/dashboard-installation"
                  component={DashboardInstallation}
                />
                <Route path="/settings" component={Settings} />
                <Route path="/workout-intro" component={WorkoutIntro} />
                <Route path="/exercise" component={Exercise} />
                <Route
                  path="/evaluation/:type/:workout_uuid"
                  component={Evaluation}
                />
                <Route path="/voice" component={Voice} />
                <Route
                  path="/staff-code-verification"
                  component={StaffCodeVerification}
                />
                <Route path="/meeting/incoming/:room_id" component={IncomingCall} />
                <Route path="/meeting/:room_id" component={Meeting} />
                <Route path="/preload" component={Preload} />
                <Route path="/" component={App} />
              </Switch>
              <Redir auth={ auth } authInstallation={ authInstallation } />
            </MeetingProvider>
          </WherebyProvider>
        </HashRouter>
      </Suspense>
    );
  })();
};

if (!window.cordova) {
  // Init Bugsnag for testing in browser
  Bugsnag.start({
    apiKey: "744d605c49889adf6f814a9045e72a21",
    plugins: [new BugsnagPluginReact()],
    appVersion: "browser",
  });
  // Start the app
  startApp();
} else {
  document.addEventListener("deviceready", startApp, false);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
