import { useEffect, useRef, useState } from "react";
import { withRouter } from 'react-router-dom';
import './BookingNotification.scss';
import ApiHelper from "../helpers/ApiHelper";
import SystemHelper from "../helpers/SystemHelper";

const BookingNotification = (props) => {
  const [message, setMessage] = useState(null);
  const interval_seconds = 60;
  const t = props.t;
  
  let interval;

  const getNextBooking = () => {
    ApiHelper.Get('/video-calls/next').then(response => {
      console.log('meeting', response);
      if (response.data.data && !response.data.data.length) {
        const meeting = response.data.data;
        const { next_day, next_hour, next_minute } = SystemHelper.getNextOccurance(meeting.date, true);
        const msg = t('meeting:booking.you_have_a_video_call_booked', { name: meeting.admin.name, day: next_day, time: next_hour + ':' + (next_minute < 10 ? '0' + next_minute : next_minute) });
        console.log('setMessage', msg);
        setMessage(msg);
      } else {
        setMessage(null);
      }
    });
  };

  useEffect(() => {
    getNextBooking();

    interval = setInterval(() => {
      getNextBooking();
    }, interval_seconds * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    (message &&
      <section id="booking-notification-view">
          <h4>{ message }</h4>
      </section>
    )
  );
};
export default withRouter(BookingNotification);
