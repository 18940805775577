import axios from 'axios';
import SystemHelper from './SystemHelper';

const DataHelper = {

    get: async function(file) {       
        file = SystemHelper.getSystemPath('/data/' + file + '.json');
        const request = new Promise(function(resolve, reject) {
            axios.get(file, {
                'content-type': 'application/json'
                })
              .then(res => {
                console.log(res.data);
                resolve(res.data);
              }).catch(res => {
                alert(JSON.stringify(res));
                reject(res);
              });
        });

        return await request;
    }

}

export default DataHelper;