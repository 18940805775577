import React, { Component } from 'react';

class ScreenOff extends Component {

    constructor(props) {
        super(props);
        this.state = {
            parentComponent: this.props.Parent
        }
        this.ready = false;
    }

    componentDidMount() {
        console.log('screen off');
        if (window.cordova?.plugins?.brightness) {
            const brightness = window.cordova.plugins.brightness;

            brightness.setBrightness(0, () => {
            }, (error) => {
                console.error(error);
            });
        }
        // Prevent that doubleclick turns screen back on
        setInterval(() => {
            this.ready = true;
        }, 500);
    }

    componentWillUnmount() {
        if (window.cordova?.plugins?.brightness) {
            const brightness = window.cordova.plugins.brightness;

            brightness.setBrightness(1, () => {
                console.log('Setting max brightness');
            }, (error) => {
                console.error(error);
            });
            brightness.setKeepScreenOn(true);
        }
    }

    render() {
        return (
            <section id="view-off" className="black" onClick={() => { if (this.ready) this.state.parentComponent.screenOn(); }}></section>
        );
    }
}

export default ScreenOff;