import i18next from "i18next";
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import axios from 'axios';
import SystemHelper from './components/helpers/SystemHelper';

class Translations {
    
    /**
     * Custom request handler for locale files
     */
    static requestBackend(options, url, payload, callback) {
        axios.get(url, {
            'content-type': 'application/json'
        })
        .then(res => {
            console.log(res.data);
            callback(null, {status: res.status, data: res.data});
        }).catch(err => {
            callback(err, null);
        });
    }
    
    static init() {
        // Get locale from device as fallback
        let locale = navigator.language ? navigator.language.split('-')[0] : 'sv';

        // Init i18next
        i18next
            .use(initReactI18next)
            .use(Backend)
            .init({
                backend: {
                    loadPath: SystemHelper.getSystemPath('/locales/{{lng}}/{{ns}}.json'),
                    request: this.requestBackend // Use custom request since default doesn't work in Cordova
                },
                fallbackLng: locale,
                debug: true
            });
    }
}

export default Translations;