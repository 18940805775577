import React, { Component } from 'react';
import Button from '../shared/Button';
import mobiscroll from '@mobiscroll/react';
import '../../styles/_mobiscroll.scss';
import '../../styles/_colors.scss';

mobiscroll.settings = {
    theme: 'ios',
    themeVariant: 'light',
    buttons: []
};

class ReminderSetting extends Component {

    constructor(props) {
        super(props);
        this.history = this.props.history;
        this.state = {
            program: props.program,
            selected_workout: 1,
            reminder_hours: [],
            selected_hour: null
        }
    }

    componentDidMount() {
        // Create items for time select
        let reminder_hours = [];
        for (let i = 0; i < 24; i++) {
            let time = (i < 10 ? '0' : '') + i + ':00';
            reminder_hours.push({ value: time, text: time });
        }
        this.setState({ reminder_hours: reminder_hours });

        // Select first workout
        this.selectWorkout(1);

        // Show time select from the start
        this.refs.time_select.instance.show();
    }

    /**
     * Select workout and show current reminder time
     * @param {Reminder number (1-4)} num 
     */
    selectWorkout(num) {
        // Set workout in state
        this.setState({ selected_workout: num });
        // Set value in select
        let value = '';
        switch (num) {
            case 1: value = this.state.program.meta?.reminder_1; break;
            case 2: value = this.state.program.meta?.reminder_2; break;
            case 3: value = this.state.program.meta?.reminder_3; break;
            case 4: value = this.state.program.meta?.reminder_4; break;
            default: value = this.state.program.meta?.reminder_1;
        }
        console.log('VALUE', value);
        console.log('PROGRAM', this.state.program.id);

        // This needs workout
        // TODO: better solution
        setTimeout(() => {
            this.refs.time_select.instance.setVal(value);
        }, 100);
    }

    /**
     * Saves the selected workout time in state
     * @param {The time string} time 
     */
    onTimeSelect(time) {
        let program = this.state.program;
        console.log('time', time);
        switch (this.state.selected_workout) {
            case 1: program.meta.reminder_1 = time; break;
            case 2: program.meta.reminder_2 = time; break;
            case 3: program.meta.reminder_3 = time; break;
            case 4: program.meta.reminder_4 = time; break;
            default: program.meta.reminder_1 = time;
        }
        console.log('program', program);
        this.setState({ program: program });

        // Save
        this.props.parent.props.parent.save();
    }

    render() {

        const { t } = this.props.parent.props.parent.state.parentComponent.props;

        return (
            <div className="workout-selection">
                <h3>{this.state.program.name}</h3>
                <div className="program-wrapper">
                    <div className="workout-select">
                        {this.state.program.meta?.reminder_1 ? (
                            <Button key={'reminder-' + this.state.program.uuid + '_1'} className={'small ' + (this.state.selected_workout === 1 ? 'blue-dark' : 'transparent')} onClick={() => this.selectWorkout(1)}>{ t('settings:panels.reminders.workout') } 1: &nbsp; <span className={(this.state.selected_workout === 1 ? 'orange' : 'white')}>{this.state.program.meta.reminder_1}</span></Button>
                        ) : ''}
                        {this.state.program.meta?.reminder_2 ? (
                            <Button key={'reminder-' + this.state.program.uuid + '_2'} className={'small ' + (this.state.selected_workout === 2 ? 'blue-dark' : 'transparent')} onClick={() => this.selectWorkout(2)}>{ t('settings:panels.reminders.workout') }  2: &nbsp; <span className={(this.state.selected_workout === 2 ? 'orange' : 'white')}>{this.state.program.meta.reminder_2}</span></Button>
                        ) : ''}
                        {this.state.program.meta?.reminder_3 ? (
                            <Button key={'reminder-' + this.state.program.uuid + '_3'} className={'small ' + (this.state.selected_workout === 3 ? 'blue-dark' : 'transparent')} onClick={() => this.selectWorkout(3)}>{ t('settings:panels.reminders.workout') }  3: &nbsp; <span className={(this.state.selected_workout === 3 ? 'orange' : 'white')}>{this.state.program.meta.reminder_3}</span></Button>
                        ) : ''}
                        {this.state.program.meta?.reminder_4 ? (
                            <Button key={'reminder-' + this.state.program.uuid + '_4'} className={'small ' + (this.state.selected_workout === 4 ? 'blue-dark' : 'transparent')} onClick={() => this.selectWorkout(4)}>{ t('settings:panels.reminders.workout') }  4: &nbsp; <span className={(this.state.selected_workout === 4 ? 'orange' : 'white')}>{this.state.program.meta.reminder_4}</span></Button>
                        ) : ''} 
                    </div>
                    <mobiscroll.Select label="" ref={"time_select"} onChange={(event) => this.onTimeSelect(event.valueText) } display="inline" cssClass="time-select" showInput={false} touchUi={true} data={this.state.reminder_hours} value={this.state.selected_hour} />
                </div> 
            </div>        
        );
    }
}

export default ReminderSetting;