import axios from "axios";
import ApiHelper from "../../helpers/ApiHelper";

const bankidLoginHelper = {
  initiate: () => {
    return new Promise((resolve) => {
      axios
        .post(ApiHelper.LoginHost + "/api/identity-number/initiate")
        .then((res) => {
          resolve({ data: res.data.data, error: undefined });
        })
        .catch((error) => {
          //   console.log(error.response);
          const { data, status, statusText } = error.response;
          resolve({
            data: undefined,
            error: {
              message: data.data.message,
              status,
              statusText,
            },
          });
        });
    });
  },
  qrCodeImageBankId: (order_ref) => {
    return new Promise((resolve) => {
      axios
        .post(ApiHelper.LoginHost + "/api/identity-number/get-qr-image", {
          order_ref,
        })
        .then((res) => {
          resolve({ data: res.data.data, error: undefined });
        })
        .catch((error) => {
          //   console.log(error.response);
          const { data, status, statusText } = error.response;
          resolve({
            data: undefined,
            error: {
              message: data.data.message,
              status,
              statusText,
            },
          });
        });
    });
  },
  checkStatusBankId: (order_ref) => {
    return new Promise((resolve) => {
      axios
        .post(ApiHelper.LoginHost + "/api/identity-number/status", {
          order_ref,
        })
        .then((res) => {
          console.log("checkStatusBankId:response", res);
          resolve({ data: res.data.data, error: undefined });
        })
        .catch((error) => {
          console.log(error.response);
          const { data, status, statusText } = error.response;
          resolve({
            data: undefined,
            error: {
              message: data.data.message,
              status,
              statusText,
            },
          });
        });
    });
  },
};
export default bankidLoginHelper;

export const resolveAfter2Seconds = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("resolved");
    }, 1500);
  });
};
