import IcecastMetadataPlayer from "icecast-metadata-player";
import DataHelper from "./DataHelper";
import SystemHelper from "./SystemHelper";
import AudioHelper from "./AudioHelper";

class MusicHelper {

  constructor() {
    this.player = null;
    this.channels = [];
    this.channel_id = null;
    this.current_url_index = 0;
    this.stop_timeout = null;
    this.current_volume = null;
    this.audio_helper = null;
    this.is_playing = false;
    this.MUSIC_VOLUME_RATIO = SystemHelper.isIos() ? 0.08 : 0.3; // Music max level ratio to other audio
    this.MAX_VOLUME = 0;
  }

  init() {
    console.log('init music');
    return new Promise(async (resolve, reject) => {
      this.getAllChannels().then(channels => {
        this.channels = channels;
        resolve();
      });
    });
  }

  /**
   * Starts playing selected music channel
   */
  async play(props) {

    this.is_playing = true;

    let abort = false;

    // Stop player if playing
    if (this.player) {
      console.log('pause in play');
      this.pause();
    }
    
    // Abort if no channels
    if (this.channels.length === 0) {
      console.log('No channels loaded. Have you initiated?');
      return;
    }

    if (!props) {
      props = {
        channel_id: this.channel_id
      };
    } else {
      this.channel_id = props.channel_id;
    }

    const url = this.getChannelUrl(this.channel_id);

    if (!url) {
      console.log('No URL set for music player!');
      return;
    }

    // Check platform
    if (!SystemHelper.isIos()) {
      // Use Icecast if not iOS
      console.log('Using Icecast');
      this.player = new IcecastMetadataPlayer(
        url,
        {
          onMetadata: (metadata) => { console.log(metadata) },
          onError: async (error) => {
            console.log('Player error', error);
            // Switch channel URL
            this.current_url_index = (this.current_url_index === 0 ? 1 : 0);
            // Wait 3 s
            await SystemHelper.timeout(3000);
            // Play again
            this.play(props);
            abort = true;
          }
        }
      );

      // Init helper
      this.audio_helper = new AudioHelper(this.player.audioElement);
    } else {
      // Init helper without Icecast
      this.audio_helper = new AudioHelper(url);
    }

    const volume_settings = await this.audio_helper.getVolumeSettings();
    this.MAX_VOLUME = (volume_settings.music / 10) * this.MUSIC_VOLUME_RATIO;

    if (abort) {
      console.log('Aborting play()');
      return;
    }

    console.log('starting play()', url);
    console.log('Initial volume', props.volume);
    console.log('Max volume', this.MAX_VOLUME);

    // Fade in if set
    if (props.fade_in === true) {
      console.log('Fading in music to', props.volume ?? this.MAX_VOLUME);
      this.audio_helper.fade(0, props.volume ?? this.MAX_VOLUME, 3);
    } else {
      this.audio_helper.setVolume(this.MAX_VOLUME);
    }


    
    // Start playing
    if (this.player) {
      this.player.play();
    } else {
      setTimeout(() => {
        this.audio_helper.play();
      }, 1000);
    }

    // Fade out music if set
    if (props.timeout) {
      if (this.stop_timeout) {
        clearInterval(this.stop_timeout);
      }
      this.stop_timeout = setTimeout(() => { this.audio_helper.fade(this.MAX_VOLUME, 0, 3) }, props.timeout * 1000);
    }
  }

  /**
   * Fade out and stop the music
   */
  stop(fadeOut) {
    console.log('stop music', fadeOut);
    return new Promise(resolve => {
      this.is_playing = false;
      if (this.audio_helper) {
        console.log('Starting music fadeout:', fadeOut);
        this.audio_helper.fade(this.MAX_VOLUME, 0, fadeOut ? 1 : 0).then(() => {
          console.log('Stopping music...');
          this.audio_helper.stop().then(() => resolve());
        });
      } else {
        resolve();
      }
    });
  }

  pause() {
    if (this.audio_helper) {
      this.mute();
      this.audio_helper.pause();
    }
  }

  resume() {
    if (this.audio_helper) {
      this.unmute();
      this.audio_helper.resume();
    }
  }

  mute() {
    if (this.audio_helper) {
      console.log('muting music');
      this.audio_helper.setVolume(0);
    }
  }

  unmute() {
    if (this.audio_helper) {
      console.log('unmuting music');
      this.audio_helper.setVolume(this.MAX_VOLUME);
    }
  }

  /**
   * Returns all available music channels from config file
   * @returns Array of music channels
   */
  getAllChannels() {
    return new Promise(async (resolve, reject) => {
      DataHelper.get('music').then(music => {
        console.log('resolving all channels');
        resolve(music.channels);
      }).catch(error => {
          reject(error);
      });
    });
  }

  /**
   * Gets the channel URL with current index
   * @param {ID of the channel} id 
   * @returns Decoded streaming URL
   */
  getChannelUrl(id) {
    let url = null;
    this.channels.forEach(channel => {
      if (channel.id === id) {
        url = channel.urls[this.current_url_index];
      }
    });
    if (url) {
      return this.decodeBase64(url);
    } else {
      return null;
    }
  }

  /**
   * Decodes string (URL) from base64
   * @param {String to decode} s 
   * @returns Decoded string
   */
   decodeBase64(s) {
    var e = {}, i, b = 0, c, x, l = 0, a, r = '', w = String.fromCharCode, L = s.length;
    var A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  
    for (i = 0; i < 64; i++) { e[A.charAt(i)] = i; }
    for (x = 0; x < L; x++) {
      c = e[s.charAt(x)]; b = (b << 6) + c; l += 6;
        while (l >= 8) { ((a = (b >>> (l -= 8)) & 0xff) || (x < (L - 2))) && (r += w(a)); }
    }
    return r;
}

}

export default MusicHelper;