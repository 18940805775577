import React from 'react';
import Preload from './components/Preload';
import './styles/_main.scss';

function App() {
  return (
    <Preload />
  );
}

export default App;
