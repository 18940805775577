import i18next from 'i18next';

const SystemHelper = {

    INTERNET_WAS_INTERRUPTED: false,

    /**
     * 
     * @param {The local file path} path 
     * @returns The full system file path
     */
    getSystemPath(path) {
        if (window.cordova) {
            //if (window.device.platform === 'Android') {
                path = window.cordova.file.applicationDirectory + 'www' + path;
            /*} else if (window.device.platform === 'iOS') {
                path = path.substr(1);
            }*/
        }
        return path;
    },

    /**
     * 
     * @param {The local file path} path 
     * @returns The file path in cdvfile:// format
     */
    getCdvPath(path) {
        return 'cdvfile://localhost/persistent/' + path;
    },

    async timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },

    dayOfWeek(num) {
        const days = [
            i18next.t('common:days.sunday'),
            i18next.t('common:days.monday'),
            i18next.t('common:days.tuesday'),
            i18next.t('common:days.wednesday'),
            i18next.t('common:days.thursday'),
            i18next.t('common:days.friday'),
            i18next.t('common:days.saturday')
        ];
        return days[num];
    },

    /**
     * Safely convert string to date
     * @param {A string in format YYYY-MM-DD hh:mm:ss} dateString 
     * @returns 
     */
    stringToDate(dateString) {
        var parts = dateString.split(/[- :]/); // Split the string into an array using the separator characters: "-", " ", and ":"
        var year = parseInt(parts[0]);
        var month = parseInt(parts[1]) - 1; // Months are zero-indexed, so subtract 1
        var day = parseInt(parts[2]);
        var hour = parseInt(parts[3]);
        var minute = parseInt(parts[4]);
        var second = parseInt(parts[5]);

        // Create the Date object
        return new Date(year, month, day, hour, minute, second);
    },

    /**
     * Calculate and translate day and time for next occurance
     * @param {The datetime string} datetime 
     * @returns 
     */
    getNextOccurance(datetime, with_minutes) {
        const dt = new Date(datetime);
        console.log('DATETIME', datetime);
        console.log('DT', dt);
        const now = new Date();
        let next_day = null;
        let next_hour = dt.getHours();
        let next_minute = dt.getMinutes();

        if ((dt.getDay() === now.getDay() && (next_hour > now.getHours())) || (with_minutes && next_hour === now.getHours() && next_minute >= now.getMinutes())) {
            next_day = i18next.t('common:days.today');
        } else if (dt.getDay() !== now.getDay()) {
            if ((dt.getDay() === now.getDay() + 1) || (dt.getDay() === 0 && now.getDay() === 6)) {
                next_day = i18next.t('common:days.tomorrow');
            } else {
                const days_en = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
                next_day = i18next.t('common:on') + ' ' + i18next.t(`common:days.${days_en[dt.getDay()]}`);
            }
        }

        if (with_minutes) {
            return { next_day, next_hour, next_minute };
        } else {
            return { next_day, next_hour };
        }
    },

    /**
     * Returns platform name
     * @returns Platform name (Android/iOS/browser)
     */
    getPlatform() {
        if (window.device) {
            return window.device.platform;
        } else {
            return 'browser';
        }
    },

    /**
     * 
     * @returns Detects if platform is iOS
     */
    isIos() {
        return (window.device && window.device.platform === 'iOS');
    },

    /**
     * 
     * @returns Detects if platform is Android
     */
    isAndroid() {
        return (window.device && window.device.platform === 'Android');
    },

    /**
     * 
     * @returns Detects if platform is browser
     */
    isBrowser() {
        return !window.device;
    },

    /**
     * Detects if user has touchscreen
     * @returns Bool indicating if touchscreen is used
     */
    isTouchScreen() {
        return 'ontouchstart' in window || navigator.msMaxTouchPoints;
    },

    /**
     * Toggle fullscreen (browser)
     */
    toggleFullscreen() {
        if (document.fullscreenElement) {
            document.exitFullscreen()
                .then(() => console.log("Document Exited from Full screen mode"))
                .catch((err) => console.error(err))
            } else {
            document.documentElement.requestFullscreen();
        }
    },

    /**
     * Use a promise to set state that can be awaited
     * @param {The component to set state in} component 
     * @param {Parameters to set} param 
     * @returns A promise that resolves when state is set
     */
    awaitState(component, param) {
        return new Promise((resolve, reject) => {
            if (component) {
                component.setState(param, () => resolve());
            } else {
                reject('Component is not set');
            }
        });
    },

    storage: {

        dbName: 'habbieDB',
        storeName: 'habbieStore',
        db: null,

 
        async init() {
            console.log('Init IndexedDB...');

            return await new Promise(async (resolve, reject) => {

                // Requesting persistent storage
                console.log('Requesting persistent storage...');
                if (navigator.storage && navigator.storage.persist) {
                    const isPersisted = await navigator.storage.persist();
                    console.log(`Persisted storage granted: ${isPersisted}`);
                }

                const request = indexedDB.open(SystemHelper.storage.dbName, 1);
            
                request.onupgradeneeded = (event) => {
                    let db = event.target.result;
                    if (!db.objectStoreNames.contains(SystemHelper.storage.storeName)) {
                        db.createObjectStore(SystemHelper.storage.storeName, { keyPath: 'key' });
                    }
                };
            
                request.onsuccess = (event) => {
                    SystemHelper.storage.db = event.target.result;
                    console.log('IndexedDB inited!');
                    resolve();
                };
            
                request.onerror = (event) => {
                    console.error('IndexedDB initialization error:', event.target.errorCode);
                    reject();
                };
            });

        },

        /**
         * Migrate from local storage (1.6.12)
         */
        migrateFromLocalStorage: async () => {
            const migrate = async (keys) => {
                const records = [];
                keys.forEach(key => {
                    const value = localStorage.getItem(key);
                    if (value) {
                        records.push({ key, value});
                    }
                });
                await SystemHelper.storage.setMany(records).then(() => {
                    keys.forEach(key => {
                        localStorage.removeItem(key);
                    });
                });
            };

            console.log('Starting migration from local storage...');
            await migrate([
                'admin_token',
                'audio_volume',
                'current_exercise_index',
                'host',
                'device_id',
                'installation_id',
                'installation_name',
                'locale',
                'program_uuid',
                'reminder_time',
                'token',
                'user_id',
                'user_name',
                'user_uuid',
                'workout_uuid',
                'program_uuid',
                'video_setting',
            ]);
            console.log('Migration done.');
        },
        
        async set(key, value) {
            console.log('Setting ', key);
            return await new Promise((resolve, reject) => {
                const transaction = SystemHelper.storage.db.transaction([SystemHelper.storage.storeName], 'readwrite');
                const objectStore = transaction.objectStore(SystemHelper.storage.storeName);

                const request = objectStore.put({ key, value });
                request.onsuccess = () => { console.log('Set with success', key); resolve(true); };
                request.onerror = (event) => reject(event.target.errorCode);
            });
        },

        async setMany(records) {
            console.log('Setting ', records);
            return await new Promise((resolve, reject) => {
                const transaction = SystemHelper.storage.db.transaction([SystemHelper.storage.storeName], 'readwrite');
                const objectStore = transaction.objectStore(SystemHelper.storage.storeName);

                records.forEach(record => {
                    objectStore.put(record).onerror = event => {
                      console.error("Failed to set record", record, event.target.error);
                    };
                });
            
                transaction.oncomplete = () => {
                    console.log("All specified records set successfully.");
                    resolve();
                };
                transaction.onerror = event => {
                    console.error("Transaction failed: ", event.target.error);
                    reject(event.target.error);
                };
            });
        },
        
        async get(key) {
            //console.log('Getting key', key);
            return await new Promise((resolve, reject) => {
                const transaction = SystemHelper.storage.db.transaction([SystemHelper.storage.storeName], 'readonly');
                const objectStore = transaction.objectStore(SystemHelper.storage.storeName);
        
                const request = objectStore.get(key);
                request.onsuccess = (event) => {
                if (event.target.result) {
                    //console.log('Getting value from', key);
                    resolve(event.target.result.value);
                } else {
                    resolve(null); // key doesn't exist
                }
                };
                request.onerror = (event) => reject(event.target.errorCode);
            });
        },
        
        async remove(key) {
            return await new Promise((resolve, reject) => {
                const transaction = SystemHelper.storage.db.transaction([SystemHelper.storage.storeName], 'readwrite');
                const objectStore = transaction.objectStore(SystemHelper.storage.storeName);
        
                const request = objectStore.delete(key);
                request.onsuccess = () => resolve(true);
                request.onerror = (event) => reject(event.target.errorCode);
            });
        },

        async removeMany(keys) {
            return await new Promise((resolve, reject) => {
                const transaction = SystemHelper.storage.db.transaction([SystemHelper.storage.storeName], 'readwrite');
                const objectStore = transaction.objectStore(SystemHelper.storage.storeName);
        
                keys.forEach(key => {
                    objectStore.delete(key).onerror = event => {
                      console.error(`Failed to delete record with key ${key}`, event.target.error);
                    };
                });
            
                transaction.oncomplete = () => {
                    console.log("All specified records deleted successfully.");
                    resolve();
                };
                transaction.onerror = event => {
                    console.error("Transaction failed: ", event.target.error);
                    reject(event.target.error);
                };
            });
        },

        
        async clear() {
            return await new Promise((resolve, reject) => {
                const transaction = SystemHelper.storage.db.transaction([SystemHelper.storage.storeName], 'readwrite');
                const objectStore = transaction.objectStore(SystemHelper.storage.storeName);
        
                const request = objectStore.clear();
                request.onsuccess = () => resolve(true);
                request.onerror = (event) => reject(event.target.errorCode);
            });
        }
    }
};

export default SystemHelper;