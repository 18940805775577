import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../shared/Button';
import '../../styles/_colors.scss';
import SystemHelper from '../helpers/SystemHelper';

class ExercisePaused extends Component {

    constructor(props) {
        super(props);
        this.history = this.props.history;
        this.state = {
            exerciseView: this.props.ExerciseView
        }
    }

    async componentDidMount() {
        this.isInstallation = await SystemHelper.storage.get('admin_token') === 'installation';
    }

    abort() {
        if (this.state.exerciseView.audio_helper) {
            this.state.exerciseView.audio_helper.stop();
        }
        if (this.state.exerciseView.music) {
            this.state.exerciseView.music.stop();
        }
        this.history.push(this.isInstallation ? '/dashboard-installation' : '/dashboard');
    }

    restart() {
        this.state.exerciseView.restart();
    }

    render() {

        const { t } = this.props.ExerciseView.props;
        const exercise_state = this.state.exerciseView.state;

        return (
            <section id="view-exercise-paused" className="white">
                <div className="wrapper middle">
                    <h2>
                        { !exercise_state.use_pose_detection || exercise_state.user_is_on_camera ?
                            <span>{ t('workout:exercise.exercise_paused') }</span>
                        :   <span>{ t('workout:exercise.place_yourself_in_front_of_camera') }</span>
                        }
                    </h2>
                    { !exercise_state.use_pose_detection || exercise_state.user_is_on_camera ?
                        <Button className="blue-dark icon-right play-white" onClick={() => this.state.exerciseView.resume()}>{ t('workout:exercise.resume_exercise') }</Button>
                        : ''
                    }
                </div>
                <div className="button-bar bottom">
                    <Button className="yellow-light" onClick={() => this.abort() }>{ t('workout:exercise.abort_exercise') }</Button>
                    { !exercise_state.use_pose_detection || exercise_state.user_is_on_camera ?
                        <Button className="blue-light" Icon="reload" onClick={() => this.restart()}>{ t('workout:exercise.restart') }</Button>
                    : '' }
                </div>
          </section>    
        );
    }
}

export default withRouter(ExercisePaused);