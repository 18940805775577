import axios from 'axios';

const FileHelper = {

    /**
     * Requests file system depending on platform
     * @param {Function to call with access to file system} callback
     * @param {Filesystem to use (ex. window.TEMPORARY) if given} fs
     */
    requestFileSystem: (callback, fs) => {
        if (window.requestFileSystem) {
            // Persistent storage in Android + iOS
            if (window.platform === 'Android') {
                window.requestFileSystem(fs ?? window.PERSISTENT, 0, callback);
            } else {
                window.requestFileSystem(fs ?? window.PERSISTENT, 0, callback);
            }
        } else {
            // Temporary storage in browser
            window.webkitRequestFileSystem(fs ?? window.TEMPORARY, 1024*1024, callback);
        }
    },

    /**
     * Get video preferences for current platform
     * Returns object with 'extension' and 'mime_type'
     */
    getPlatformVideoPreferences() {
        let pref = { extension: '', mime_type: '' };
        // If Cordova app
        if (window.device) {
            if (window.device.platform === 'Android') {
                pref.extension = 'webm';
                pref.mime_type = 'video/webm';
                pref.source_type = 'video/webm; codecs="vp9,opus"';
            } else {
                pref.extension = 'm4v';
                pref.mime_type = 'video/x-m4v';
                pref.source_type = 'video/x-m4v';
            }
        }
        // TODO:
        // Else check OS
        else {
            pref.extension = 'webm';
            pref.mime_type = 'video/webm';
            pref.source_type = 'video/webm; codecs="vp9,opus"';
        }
        return pref;
    },

    /**
     * Get file from device storage using File API
     * @param {File path} path 
     * @param {Filesystem to use (ex. window.TEMPORARY) if given} fs
     */
    get: async (path, fs) => {
        const request = new Promise(async (resolve, reject) => {
            const getFile = (storage) => {
                storage.root.getFile(path, { create: false }, (fileEntry) => {
                    // Resolve with FileEntry object
                    resolve(fileEntry);
                }, (error) => {
                    console.error('No local file (' + JSON.stringify(error) + ')', path);
                    // Resolve with null
                    resolve(null);
                });
            };
            FileHelper.requestFileSystem(getFile, fs);
        });
        return await request;
    },

    /**
     * Get storage root path
     */
    getRootPath: () => {
        const request = new Promise((resolve) => {
            const getDirectory = (storage) => {
                storage.root.getDirectory('/', {}, (directory) => {
                    resolve(directory.toURL());
                })
            };
            FileHelper.requestFileSystem(getDirectory);
        });
        return request;
    },

    /**
     * Converts file to blob and returns URL
     * @param {Original path} path 
     * @param {MIME type} mime 
     */
    async getLocalUrl(path, mime) {
        const request = new Promise(async (resolve, reject) => {
            await this.get(path).then((fileEntry) => {
                if (fileEntry) {
                    fileEntry.file(file => {
                        const reader = new FileReader();
                        reader.onloadend = (event) => {
                            const blob = new Blob([new Uint8Array(event.target.result)], { type: mime });
                            const blob_url = window.URL.createObjectURL(blob);
                            resolve(blob_url);
                        };
                        reader.onerror = (error) => {
                            reject(error);
                        };
                        reader.readAsArrayBuffer(file);
                    });
                } else {
                    resolve(null);
                }
            }).catch((error) => {
                reject(error);
            });
        });
        return request;
    },

    /**
     * Get content length from FileEntry object
     * @param {FileEntry object} file 
     */
    getFileEntryLength: async(file) => {
        const request = new Promise(async (resolve) => {
            file.getMetadata((meta) => {
                resolve(meta.size);
            });
        });
        return await request;
    },

    /**
     * Get content length from remote HEAD request
     * @param {The remove file path} path 
     * @returns 
     */
    getFileHeaderLength: async(path) => {
        const request = new Promise(async (resolve, reject) => {
            let content_length = 0;
            await axios.head(path + '?' + new Date().getTime(),
                {
                    accept: '*/*',
                    headers: {
                      'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': '0'
                    }
                }
            ).then((response) => {
                if (response.status !== 200 && response.status !== 204) {
                    reject('ERROR FETCHING FILE: ' + response.status + ' (' + response.url + ')');
                }
                content_length = parseInt(response.headers['content-length']);
                console.log(path + ' is ', content_length);
                resolve(content_length);
            }).catch((error) => {
                reject(error + ' ' + path);
            });
        });
        return request;
    },

    /**
     * Get total content length in bytes
     * @param {Array of paths} paths
     */
    getContentLength: async (paths) => {
        const request = new Promise(async (resolve, reject) => {
            let total_content_length = 0;
            for (let i = 0; i < paths.length; i++) {
                // Skip if no path
                if (paths[i] === null) {
                    continue;
                }
                // Add to content length
                total_content_length += await FileHelper.getFileHeaderLength(paths[i]).catch(error => {
                    console.log('ERROR', error);
                    reject(error);
                });

            }
            console.log('resolve...');
            resolve(total_content_length);
            
        });
        return await request;
    },

    /**
     * Preload file
     */
    preload: async (url, onProgress) => {
        const request = new Promise(async (resolve, reject) => {
            // Get response and progress
            await axios.get(url + '?' + new Date().getTime(), {
                accept: '*/*', 
                responseType: 'arraybuffer',
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                }
            }).then((response) => {
                // Check if file is found
                // Reject and abort if not
                if (response.status !== 200) {
                    reject('Error fetching file: ' + response.status);
                    return;
                } else {
                    // Update progress when file is downloaded
                    let content_length = parseInt(response.headers['content-length']);
                    onProgress(content_length);
                    // Resolve with file data
                    resolve(response.data);
                }
            });
            
        });
        return await request;
    },

    /**
     * Get duration of video in sec
     */
    getVideoDuration: async (url) => {
        const request = new Promise(async (resolve, reject) => {

            // Resolve on metadataloaded event
            let loaded = (event) => {
                resolve(event.target.duration);
            };

            let video = document.createElement('video');
            // Use event listener for Android (and browser)
            //if (!window.device || window.device.platform === 'Android') {
                video.addEventListener('loadedmetadata', loaded);
            /*}
            // Use interval for iOS
            else {
                let check_meta = null;
                let tries = 0;
                check_meta = setInterval(() => {
                    if (video.duration) {
                        clearInterval(check_meta);
                        resolve(video.duration);
                    } else {
                        console.log('no duration', video.duration);
                        tries++;
                        // Abort trying after 5 s
                        if (tries === 10) {
                            clearInterval(check_meta);
                            reject();
                        }
                    }
                }, 500);
            }
            */
            
            // Get video preferences for platform
            let video_pref = FileHelper.getPlatformVideoPreferences();

            await FileHelper.getLocalUrl(url, video_pref.mime_type).then((local_url) => {
                video.src = local_url;
                video.preload = 'metadata';
                video.onerror = (error) => {
                    console.error('Error loading local video: ' + local_url);
                    console.log(error);
                    reject(error);
                };
                video.load();
            });
        });

        return request;
    },

    /**
     * Removes a file if exists
     * @param {The file name} filename 
     */
    delete: (storage, filename) => {
        const request = new Promise(async (resolve) => {
            storage.root.getFile(filename, { create: false }, (file) => {
                // File exists, so remove it
                file.remove(() => resolve(true));
            },
            () => { resolve(false); });
        });
        return request;
    },

    /**
     * Save remote file in device storage using File API
     * @param {File name} url 
     */
    save: async (url, filename, onProgress) => {
        const request = new Promise(async (resolve, reject) => {
            // Preload the file
            FileHelper.preload(url, onProgress).then((data) => {

                console.log('Progress is done for ' + url);

                // Create blob
                const blob = new Blob([data]);
                // Save the file
                const saveFile = (storage) => {
                    FileHelper.delete(storage, filename).then((deleted) => {
                        console.log('Deleted: ' + deleted);
                        console.log('Creating file...');
                        storage.root.getFile(filename, { create: true, exclusive: false }, (file) => {
                            console.log('Creating writer...');
                            file.createWriter((content) => {
                                content.write(blob);
                                console.log('Saved as', file.toURL());
                                resolve();
                            });
                        }, (error) => {
                            console.log('Error creating file', JSON.stringify(error));
                        });
                    });
                };
                FileHelper.requestFileSystem(saveFile);
            }).catch((error) => {
                console.error('ERROR SAVING ' + filename);
                reject(error);
            });
        });
        return await request;
    },
    
     /**
     * Get file on device as b64
     */
    getFileAsBase64:(path,callback)=> {
        window.resolveLocalFileSystemURL(path, gotFile, fail);
                
        function fail(e) {
              alert('Cannot found requested file');
        }
    
        function gotFile(fileEntry) {
               fileEntry.file(function(file) {
                  var reader = new FileReader();
                  reader.onloadend = function(e) {
                       var content = this.result;
                       callback(content);
                  };
                  // The most important point, use the readAsDatURL Method from the file plugin
                  reader.readAsDataURL(file);
               });
        }
    },

    /**
     * Get b64 file on device as blob
     */
    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

      var blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },
    
    /**
     * Delete file on device (tested on recording android)
     */
    deleteFile(path_to_file_dir,filename) {
        return new Promise(function(resolve, reject){
            window.resolveLocalFileSystemURL(path_to_file_dir, function (dir) {
                dir.getFile(filename, {create: false}, function (fileEntry) {
                    fileEntry.remove(function (file) {
                        resolve("file removed " + filename);
                    }, function (error) {
                        reject(error.code);
                    }, function () {
                        reject("file does not exist");
                    });
                });
            });
        });
    }
}

export default FileHelper;