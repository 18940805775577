import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { useLocalMedia, useRoomConnection, VideoView } from "@whereby.com/browser-sdk/react";
import Button from './../shared/Button';
import { withTranslation, useTranslation } from 'react-i18next';
import './Meeting.scss';
import ApiHelper from '../helpers/ApiHelper';
import SystemHelper from '../helpers/SystemHelper';

const Meeting = () => {

    const { room_id } = useParams('room_id');
    const history = useHistory();
    const [userUuid, setUserUuid] = useState(null);

    useEffect(() => {
        const getUuid = async () => {
            const user_uuid = await SystemHelper.storage.get('user_uuid');
            setUserUuid(user_uuid);
        };
        getUuid();
    }, []);

    return (
        (userUuid &&
            <MeetingView user_uuid={ userUuid } room_id={ room_id } history={ history } />
        )
    )
};

function MeetingView( { user_uuid, room_id, history }) {

    const { state, actions } = useRoomConnection(
        "https://habbie.whereby.com/" + room_id,
        {
            localMediaOptions: {
                audio: true,
                video: true,
            },
            displayName: user_uuid,
        }
    );

    const { remoteParticipants, connectionStatus } = state;
    const { joinRoom, leaveRoom, knock } = actions;
    const localMedia = useLocalMedia({ audio: true, video: true });
    const [videoReady, setVideoReady] = useState(false);

    const { t } = useTranslation(['common', 'meeting']);
    
    useEffect(() => {
        joinRoom();
    }, []);

    useEffect(() => {
        if (connectionStatus === 'room_locked') {
            knock();
        }

        const adminView = document.getElementById('meeting-admin-view');

        if (adminView) {
            const observer = new MutationObserver(() => {
                const videoElement = adminView.querySelector('video');

                if (videoElement) {
                    const handlePlaying = () => {
                        console.log('admin video is now playing');
                        setVideoReady(true);
                    };
    
                    const handleLoadedData = () => {
                        console.log('admin video has loaded data');
                        // Wait for 'playing' to confirm it's truly showing
                        videoElement.addEventListener('playing', handlePlaying, { once: true });
                    };
    
                    videoElement.addEventListener('loadeddata', handleLoadedData, { once: true });

                    // Disconnect the observer once we’ve found the video element
                    observer.disconnect();

                    // Cleanup the event listener on unmount
                    return () => {
                        videoElement.removeEventListener('loadeddata', handleLoadedData);
                    };
                }
            });

            // Observe child elements added to #meeting-admin-view
            observer.observe(adminView, { childList: true, subtree: true });

            // Cleanup observer on component unmount
            return () => observer.disconnect();
        }
    }, [connectionStatus])
    
    const leave = () => {

        leaveRoom();

        ApiHelper.Post(`/video-calls/${ room_id }/leave`).then(response => {
            history.push('/dashboard');
            window.location.reload();
        }).catch(error => {
            console.log(error);
        });
    };

    return <div id="meeting-view">
        <div id="meeting-admin-view">
        { remoteParticipants.map((p) => (
            <div className={'video-view' + (!videoReady ? ' hidden' : '')} key={p.id}>
                <VideoView stream={p.stream} />
            </div>
        )) }
        </div>
        <div id="meeting-self-view">
            <SelfView localMedia={localMedia} />
        </div>
        <div className="button-bar bottom">
            <Button className="red" Icon="phone-off" onClick={ leave }>{ t('meeting:call.end_call') }</Button>
        </div>
    </div>;
}

function SelfView({ localMedia }) {
    const { localStream } = localMedia.state;

    return (
        <VideoView muted mirror="true" stream={localStream} />
    );
}

export default withRouter(withTranslation(['meeting', 'common'])(Meeting));