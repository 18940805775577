import React, { Component } from "react";
import { v4 as uuidv4 } from "uuid";
import "../../styles/_buttons.scss";
import "../../styles/_colors.scss";
import SystemHelper from "../helpers/SystemHelper";

class Button extends Component {
  constructor(props) {
    super(props);
    this.id = uuidv4();
    this.label = null;
  }

  /**
   * Check for text label recursively
   * @param {Children of object} children
   * @returns
   */
  getButtonLabel(children) {
    if (!children) {
      return null;
    } else if (typeof children === "string") {
      // We have found a string, return it
      return children;
    } else {
      // Look for grandchildren
      let label = null;
      React.Children.map(children, (child) => {
        if (child) {
          if (child.type === "string") {
            label = child;
          } else {
            if (child.props) {
              label = this.getButtonLabel(child.props.children);
            }
          }
        }
      });
      return label;
    }
  }

  componentDidMount() {
    // Get button label
    this.label = this.getButtonLabel(this.props.children);
  }

  clickEvent() {
    // set on top class
    let event = this.props.onClick;
    event();
  }

  render() {
    return (
      <button
        id={this.id}
        parent={this.props.parent}
        className={this.props.className + (this.props.hidden ? " hidden" : "")}
        style={this.props.style}
        onClick={() => {
          if (!SystemHelper.isTouchScreen()) {
            this.clickEvent();
          }
        }}
        disabled={this.props.disabled}
        onTouchEnd={() => {
          if (SystemHelper.isTouchScreen()) {
            this.clickEvent();
          }
        }}
      >
        {this.props.children}
        {this.props.IconLeft ? (
          <span className={"icon left " + this.props.IconLeft}></span>
        ) : (
          ""
        )}
        {this.props.Icon ? (
          <span className={"icon " + this.props.Icon}></span>
        ) : (
          ""
        )}
      </button>
    );
  }
}

export default Button;
