import Pusher from 'pusher-js';
import i18next from 'i18next';
import SystemHelper from '../helpers/SystemHelper';
import DataHelper from '../helpers/DataHelper';

class Reminder {

    constructor(props) {
        this.reminder_time = null;
        this.reminder_timeout = null;
        this.reminded_times = 0;

        let file = SystemHelper.getSystemPath('/assets/audio/reminder-alarm.mp3');
        this.alarm = new Audio(file);
        this.alarm.volume = 1;
        this.alarm.loop = true;
        this.channel_name = 'reminder-channel-' + props.uuid;
        this.locale = props.locale;

        // Init Pusher
        Pusher.logToConsole = true;

        this.pusher = new Pusher('9e2dfdb329be9e1384bb', {
            cluster: 'eu',
        });

        this.channel = this.pusher.subscribe(this.channel_name);

        console.log('Listenting to channel for ' + props.uuid);

        this.channel.bind('reminder-event', function(data) {
            props.onstart();
        });

        // Set language
        if (this.locale) {
            this.changeLanguage(this.locale);
        }
    }

    /**
     * Change language for reminder
     * @param {Locale for new language} locale 
     */
    changeLanguage(locale) {
        console.log('change language', locale);
        i18next.changeLanguage(locale);
    }

    /**
     * Re-activate the alarm after a certain interval if not suspended
     * @param {Properties for interval etc.} props 
     */
    async reActivateAlarm(props) {
        const component = this;
        return new Promise((resolve, reject) => {
            let interval_ms = 0;

            // If given time
            if (props && props.interval_minutes) {
                interval_ms = props.interval_minutes * 1000 * 60;
            }

            // Clear timeout
            component.clear();

            // Set timeout
            component.reminder_timeout = setTimeout(() => {
                // Start the alarm sound
                component.inactivate().then(() => {
                    console.log('start alarm again!');
                    component.start({
                        onstart: props.onstart
                    });
                })},
                interval_ms
            );

            console.log('Wait ' + interval_ms + ' ms');

            //console.log('reminder activated for', this.reminder_time);

            // Resolve with time until start
            resolve(interval_ms);
        });
    }

    /**
     * Inactivate the alarm
     */
    async inactivate() {
        return new Promise((resolve) => {
            // Clear timeout
            this.clear();
            // Stop if playing
            this.stop().then(() => {
                console.log('reminder inactivated');

                // Resolve the promise
                resolve();
            });
        });
    }

    /**
     * Unsubscribe to websocket channel
     */
    unsubscribe() {
        // Unsubscribe to websocket channel
        this.pusher.unsubscribe(this.channel_name);
        console.log('unsubscribed to channel');
    }

    /**
     * Clear timeout if set
     */
    clear() {
        if (this.reminder_timeout) {
            clearTimeout(this.reminder_timeout);
        }
    }

    /**
     * Start the sound alarm
     * @param {Properties for onstart etc.} props 
     */
    async start(props) {
        console.log('Start the alarm!');
        
        const component = this;
        return new Promise(async (resolve) => {

            // Stop if already playing
            this.stop();

            // Play sound
            this.alarm.play();

            // Clear timeout if set
            if (this.reminder_timeout) {
                clearTimeout(this.reminder_timeout);
            }

            // Get config
            await DataHelper.get('config').then(async (config) => {
                component.config = config;
            });

            this.reminded_times++;

            // Set timeout to stop alarm after given time
            this.reminder_timeout = setTimeout(() => {
                component.inactivate().then(() => {
                    // Re-activate with start in given time
                    // If limit hasn't been reached
                    if (this.reminded_times < component.config.reminder_amount) {
                        component.reActivateAlarm({
                            interval_minutes: component.config.reminder_idle_minutes,
                            onstart: props.onstart
                        });
                        console.log('Reminder will start again in ' + component.config.reminder_idle_minutes + ' minutes');
                    }
                })},
                1000 * 60 * component.config.reminder_play_minutes
            );

            if (props && props.onstart) {
                props.onstart();
            }
            
            // Resolve the promise
            resolve();
        });
    }

    /**
     * Stop the alarm sound
     */
    async stop() {
        return new Promise((resolve) => {
            if (this.alarm) {
                console.log('stop it');
                this.alarm.pause();
                this.alarm.currentTime = 0;
            }
            // Resolve the promise
            resolve();
        });
    }
}

export default Reminder;