import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Settings from './settings/Settings';
import Button from './shared/Button';
import ScreenOff from './shared/ScreenOff';
import DataHelper from './helpers/DataHelper';
import InstallationHelper from './helpers/InstallationHelper';
import SystemHelper from './helpers/SystemHelper';
import PermissionsChecker from './shared/util/PermissionsChecker';
import './DashboardInstallation.scss';

    /**
     * Return program preview item
     * @param {Properties from controller} props 
     */
     function ProgramPreviews(props) {

        // Get translation
        const t = i18next.getFixedT(null, 'dashboard_installation', null);

        if (!props.programs) {
            const dummies = [1, 2, 3];
            const items = dummies.map(dummy =>
                <div className="card shadow white" key={'dummy'+dummy}>
                    <div className="image">
                        <div className="circle"></div>
                    </div>
                    <div className="text">
                        <h4><Skeleton /></h4>
                        <h5><Skeleton /></h5>
                    </div>
                    <div className="arrow">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 0L13.18 2.82L24.34 14H0V18H24.34L13.18 29.18L16 32L32 16L16 0Z" fill="black"/>
                        </svg>
                    </div>
                </div>
            );
            return items;
        }
        else if (props.programs.length > 0) {
            return props.programs.map((program) => {
                return (
                    <div key={program.id} className="card shadow white" onClick={() => props.controller.startProgram(program)}>
                        <div className="image">
                            <img src={program.exercises.length > 0 ? program.exercises[0].image : "#"} alt="" />
                        </div>
                        <div className="text">
                            <h4>{ program.name }</h4>
                            <h5>{ program.exercises.length } { (program.exercises.length === 1 ? t('dashboard_installation:exercise') : t('dashboard_installation:exercises') ) }</h5>
                        </div>
                        <div className="arrow">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 0L13.18 2.82L24.34 14H0V18H24.34L13.18 29.18L16 32L32 16L16 0Z" fill="black"/>
                            </svg>
                        </div>
                    </div>
                );
            });
        }
        else {
            return [];
        }
    }

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.history = this.props.history;
        this.state = {
            off: false,
            settings: false,
            screen_sleep_minutes: null,
            programs: null,
            fullscreen: false,
        };

        this.config = {};
        this.screenTimeout = null;
        this.programsInterval = null;

        this.scrollRef = React.createRef();
    }

    async componentDidMount() {

        const component = this;
        
        this.isInstallation = await SystemHelper.storage.get('admin_token') === 'installation';

        // Load installation programs
        await this.loadPrograms();

        // Load programs every 30 seconds
        this.programsInterval = setInterval(this.loadPrograms, 30000);

        // Check for permissions
        let pc = new PermissionsChecker(window);
        pc.check();

        // Get config
        await DataHelper.get('config').then(async (config) => {
            component.config = config;
            // Save screen sleep minutes in state
            console.log('setting config...')
            this.setState({ screen_sleep_minutes: config.screen_sleep_minutes }, () => {
                // Init screen timeout
                component.initScreenTimeout();
            });
        });

        // Reset current exercise index
        await SystemHelper.storage.remove('current_exercise_index');

        const scrollContainer = this.scrollRef.current;

        // Add the wheel event listener
        scrollContainer.addEventListener('wheel', this.handleWheel);
    }
    
    /**
     * Loads installation programs into state
     */
    loadPrograms = async() => {
        return new Promise(async (resolve, reject) => {
            InstallationHelper.getPrograms().then(async (response) => {
                this.setState({ programs: response }, () => {
                    console.log('---- programs are set', this.state.programs);
                    resolve();
                });
            }).catch(error => {
                console.error(error);
                reject();
            });
        });
    }

    /**
     * Wake up screen
     */
    wakeUp() {
        console.log('----- WAKEUP');

        // Turn on screen
        this.screenOn();

        // Save and hide settings if showing
        if (this.state.settings) {
            this.setState({ settings: false });
        }
    }

    componentWillUnmount() {
        // Turn screen on
        this.screenOn();

        if (this.programsInterval) {
            clearInterval(this.programsInterval);
        }

        const scrollContainer = this.scrollRef.current;

        // Remove the event listener for scrolling
        scrollContainer.removeEventListener('wheel', this.handleWheel);
    }

    initScreenTimeout() {
        // Clear if timeout already exists
        if (this.screenTimeout) {
            clearInterval(this.screenTimeout);
        }

        // Abort if no sleep timeout set
        if (!this.state.screen_sleep_minutes) {
            return;
        }

        const component = this;

        // Shut down screen in five min
        this.screenTimeout = setTimeout(function() {
            component.screenOff();
        }, 60000 * component.state.screen_sleep_minutes);
    }

    /**
     * Turn off screen
     */
    screenOff() {
        this.setState({ off: true });
    }

    /**
     * Turn on screen
     */
    screenOn() {
        // Set state to hide off screen
        this.setState({ off: false });

        // Reset screen timeout
        this.initScreenTimeout();
    }

    /**
     * Handle click on view
     */
    clickView() {
        // Wake up screen if off
        if (this.state.off) {
            this.wakeUp();
        }
    }
    
    startProgram(program) {
        this.history.push({
            pathname: '/workout-intro',
            state: { program }
        });
    }

    /**
     * Open settings
     */
    openSettings(card) {
        this.setState({ settingsCard: card, settings: true });
    }

    /**
     * Handle mouse wheel for horizontal scrolling
     */
    handleWheel = (e) => {
        if (window.innerWidth > window.innerHeight) {
            e.preventDefault(); // Prevent vertical scrolling
            this.scrollRef.current.scrollLeft += e.deltaY + e.deltaX; // Scroll horizontally using both vertical and horizontal
        }
    }

    render() {

        const { t } = this.props;

        return (
            <>
            <div>
                <SkeletonTheme color="#eecbc5" highlightColor="#eed9d6">
                    <section id="view-dashboard-installation" className="blobs-light" onClick={() => this.clickView()}>
                        <div className="header small">
                            { SystemHelper.getPlatform() === 'browser' ?
                            <div className={( this.state.fullscreen ? 'fullscreen off' : 'fullscreen on')} onClick={() => { SystemHelper.toggleFullscreen(); this.setState({ fullscreen: !this.state.fullscreen }); }}></div>
                            : ''}
                            <h2>{ t('dashboard_installation:select_program') }</h2>
                            <p>{ t('dashboard_installation:what_would_you_like') }</p>
                        </div>

                        { this.state.programs && this.state.programs.length === 0 ? (
                        <div className="text-wrapper">
                            <h3>{ t('dashboard_installation:no_program') }</h3>
                            <h4>{ t('dashboard_installation:contact_staff') }</h4>
                        </div>
                        ) : ''}

                        <div className="programs-wrapper" ref={this.scrollRef}>
                            <div className="programs">
                                <ProgramPreviews programs={this.state.programs} controller={this} />
                                <div className="space">&nbsp;</div>
                            </div>
                        </div>

                        <div className="button-bar bottom">
                            <Button className="blue-light center" Icon="settings" onClick={() => this.openSettings() }>{ t('dashboard:buttons.settings') }</Button>
                            <Button className="transparent center" onClick={() => this.screenOff() }>{ t('dashboard:buttons.shutdown') }</Button>
                        </div>
                </section>

                { // Screen is off
                ( this.state.off ? (
                    <ScreenOff Parent={this} />
                ) : '' )}

                { // Show settings
                ( this.state.settings ? (
                    <Settings Parent={this} card={this.state.settingsCard} />
                ) : '' )}

            </SkeletonTheme>
            </div>
            </>
        );
    }
}

export default withRouter(withTranslation(['dashboard_installation', 'dashboard', 'settings'])(Dashboard));
