
import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Pusher from 'pusher-js';
import SystemHelper from '../helpers/SystemHelper';
import UserHelper from '../helpers/UserHelper';
import ApiHelper from '../helpers/ApiHelper';

// Create a context
const MeetingContext = createContext();

// Init Pusher
Pusher.logToConsole = true;

const pusher = new Pusher('9e2dfdb329be9e1384bb', {
    cluster: 'eu',
});

// Create a provider component
const MeetingProvider = ({ children }) => {

    const [userUuid, setUserUuid] = useState(null);
    const history = useHistory();
    const channelRef = useRef(null);

    useEffect(() => {
        if (!userUuid) {
            return;
        }

        channelRef.current = pusher.subscribe('meeting-call-' + userUuid);

        console.log('Subscribed to meeting-call-' + userUuid);

        channelRef.current.bind('App\\Events\\UserLeftVideoCall', function (data) {
            // Only redirect if already in meeting or call
            if (history.location.pathname.indexOf('/meeting/') === 0) {
                history.push({
                    pathname: '/dashboard',
                    state: data
                });
                window.location.reload();
            }
        });

        channelRef.current.bind('App\\Events\\VideoCallStarted', function (data) {
            history.push({
                pathname: '/meeting/incoming/' + data.room_id,
                state: data
            });
        });
    }, [userUuid, history]);

    const subscribe = () => {
        // Get user and set uuid to init meeting listener
        UserHelper.getUser().then(async (user) => {
            console.log('get user', user);
            if (user?.uuid) {
                setUserUuid(user.uuid);
            }
        });
    };

    const unsubscribe = async () => {
        if (userUuid && channelRef?.current) {
            channelRef.current.unbind();
            pusher.unsubscribe('meeting-call-' + userUuid);
        }
    };

    useEffect(() => {
        // Don't subscribe if this is an installation
        const checkInstallation = async () => {
            const isInstallation = await SystemHelper.storage.get('admin_token') === 'installation';
            console.log('is installation', isInstallation);
            if (!isInstallation && ApiHelper.Host) {
                subscribe();
            }
        };

        checkInstallation();
    }, []);

    return (
        <MeetingContext.Provider value={{ subscribe, unsubscribe }}>
            {children}
        </MeetingContext.Provider>
    );
};

// Create a hook to use the context
const useMeeting = () => useContext(MeetingContext);

export { MeetingProvider, useMeeting, MeetingContext };