import { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "./LoginType.scss";
import Button from "./shared/Button";

class LoginType extends Component {
  constructor(props) {
    super(props);
    this.history = this.props.history;
  }

  render() {
    const { t } = this.props;

    return (
      <section id="login-type-view" className="blobs-light">
        <div className="wrapper middle">
          <h2>{ t('login:welcome') }</h2>
          <h3>{ t('login:select_login_method') }</h3>
        </div>
        <div className="button-bar bottom">
            <Button
              className="blue-dark auto-width"
              onClick={() => this.history.push("/login/numeric")}
            >
              { t('login:numeric_code_label') }
            </Button>
        
            <Button
              className="blue-dark auto-width"
              onClick={() => this.history.push("/login/bankid")}
              Icon={"bankid-white"}
            >
              { t('login:bankid_label') }
            </Button>
          </div>
      </section>
    );
  }
}

export default withRouter(
  withTranslation(["login", "common"])(LoginType)
);
